var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "message-page-header" },
    [
      _c(
        "div",
        { staticClass: "back-to-messagelist", on: { click: _vm.back } },
        [_c("b-icon-chevron-left"), _vm._v("話題一覧に戻る")],
        1
      ),
      !_vm.isMobile
        ? _c("div", { ref: "topic-title", staticClass: "topic-title" }, [
            _vm._v(_vm._s("話題: " + _vm.titleLabel))
          ])
        : _vm._e(),
      _c("b-tooltip", {
        attrs: {
          target: function() {
            return _vm.$refs["topic-title"]
          },
          title: _vm.titleLabel
        },
        on: {
          show: function($event) {
            return _vm.onTooltipShow($event, "topic-title")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }