















































































import { mixins } from "vue-class-component";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import "./comment-list.scss";
import CommentListItem, { CommentListItemData } from "./CommentListItem.vue";
import IconEnter from './icon/IconEnter.vue';
import { AttachmentFactory, Comment, User } from '../model';
import FileListViewer from './FileListViewer.vue';
import type { PayloadRegisterSubscription } from "../store/comment-store";
import DeleteModal from "./DeleteModal.vue";
import ErrorModal from './ErrorModal.vue'
import { PhotoStreamArgs } from './PhotoStream.vue';
import TextProcessMixin from './mixin/TextProcessMixin';

import { v4 as uuidv4 } from "uuid";

import { ReactionController } from "../model/reaction-controller";
import { Attachment, IconReactionType } from "../API";
import LoadingUpload from './loading/LoadingUpload.vue';
import { BFormTextarea } from "bootstrap-vue";
import AclManager from "../model/acl-manager";
import { allowFeature, FeatureName } from "../direct-app-config";
import FileSelectionForm from "./FileSelectionForm.vue";
import DragAreaOverlay from './DropAreaOverlay.vue';
import { AttachmentFileTypes, AttachmentFileTypesDefault, AttachmentFileTypesNone } from "@/suppport-attachment-types";
import { SORT_TYPE } from "@/store";

const COMMENT_CONTENT_MAX_LENGTH = 300;

@Component({
    components: {
        CommentListItem, IconEnter, FileListViewer, DeleteModal, ErrorModal, LoadingUpload, FileSelectionForm, DragAreaOverlay,
    }
})
export default class CommentList extends mixins( Vue, TextProcessMixin ) {
    name: string = 'comment-list';
    text: string = '';
    target: FileList | null = null;
    sendFiles: File[] = [];
    param: { messageId: string, commentId: string } = {messageId: '', commentId: ''};

    // コメント編集画面への引数
    modalCommentId: string = '';
    modalMessageId: string = '';
    modalMessage: string =  '';
    modalPhotos: Attachment[] = [];

    // コメント削除画面への引数
    deleteId: string = '';

    drag: boolean = false;
    focus: boolean = false;

    submitLock: boolean = false;
    allow_submit: boolean = false;

    /* アップローディング関係 */
    isUploading: boolean = false;
    uploadFiles: number = 0;
    nowuploads: number = 0;

    // PDFサムネイル
    pdfThumbStore: File[] = []

    fileTypeErrorMsg: string = "下記の添付ファイルは対応しておりません。";

    // コメントIDが指定されていて scrollTo する場合の実行フラグ
    // true: スクロールジャンプ予定で、未だスクロールジャンプしていない
    private scrollJumpFlag = false;


    @Prop({ default: '', required: true}) // 親messageIdを引き継ぐ
    readonly id!: string;

    @Prop({ default: [] })
    readonly comments!: CommentListItemData[];
    // コメントリストのウォッチャー：URLにコメントID指定がある場合に利用する
    @Watch( "comments", { deep: true, immediate: true } ) onCommentsChanged( val: CommentListItemData[] ) {
        if( this.scrollJumpFlag == false ) return;    // 自動スクロールOFF
        if( !this.$route ) return;
        const cmtId = this.$route.params.commentId;
        if( !cmtId ) return;
        const targetId = cmtId;

        // コメントID指定の場合の存在チェック
        const comment = val.find( comment => comment.id == cmtId );
        if( !comment ) return;  // 次の更新待ち

        // 存在するのでスクロールジャンプ
        this.scrollJumpFlag = false;
        this.$nextTick( () => {
            const toId = `#cmt_${targetId}`
            setTimeout( () => {
                ( this as any ).$scrollTo( toId, 0, { offset: -10, container: "#msglist" } )
            }, 1000 )
        })
    }

    @Prop({ default: () => [] }) readonly users!: User[]; // 組織内ユーザー情報
    @Prop({ default: () => ( User.createNotFoundUser() ) }) readonly viewer!: User; // ログインユーザ情報

    // Direct組織設定
    @Prop({ default: () => AttachmentFileTypesDefault }) readonly allow_attachment_type!: AttachmentFileTypes;

    @Prop({ default: "ASC" }) readonly sort!: SORT_TYPE;

    // 改行されているかの判定
    get includesNewLine(): boolean {
        return new RegExp(/\r?\n/, 'g').test(this.text);
    }

    // 添付可能か
    get allow_attachment(): boolean { return this.allow_attachment_type != AttachmentFileTypesNone; }

    // コメント一覧は作成順で取得する（話題や投稿とは順序が違う）
    get commentList (): CommentListItemData[] {
        // コメントのソート
        const desc = this.sort === "DESC";

        if( desc ) {
            this.comments.sort( (l, r) => r.createdAt.getTime() - l.createdAt.getTime() );
        } else {
            this.comments.sort( (l, r) => l.createdAt.getTime() - r.createdAt.getTime() );
        }

        const ctl = new ReactionController( this.$store );
        this.comments.forEach( comment => {
            comment.like = ctl.getReaction( comment, IconReactionType.LIKE );
        })
        return this.comments;
    }

    get editModalId(): string {
        return `comment-edit-modal-${this.id}`; // 投稿IDでモーダルをユニーク化
    }

    get deleteModalId(): string {
        return `delete-modal-${this.id}`; // 投稿IDでモーダルをユニーク化
    }

    get attachementLabelStyle(): string {
        return this.allow_attachment ? "cursor: pointer;" : "cursor: not-allowed;";
    }

    get contentState(): boolean | null {
        const trimmed = this.text.trim();
        if( !this.text ) return false;
        // コメント入力にfocusされておらず、コメントが入力されてない時にバリデーションを外す
        if( !this.focus && this.getLength(trimmed) == 0 ) { return null; }
        // トリミング後の文字数が0より多く、入力文字数が規定数以下のときに送信可能
        else { return this.getLength(trimmed) > 0 && this.getLength(this.text) <= COMMENT_CONTENT_MAX_LENGTH; }
    }

    get validCommentMessage(): string {
        return `コメントは${COMMENT_CONTENT_MAX_LENGTH}文字以内です(${this.getLength(this.text)}/${COMMENT_CONTENT_MAX_LENGTH})`;
    }

    get showCameraButton(): boolean {
        if( !this.$store ) return false;
        return this.$store.getters["isAndroid"];
    }

    get loadingMsg(): string {
        return `添付ファイルを送信中です (${this.nowuploads}/${this.uploadFiles})`;
    }

    // 権限
    get domainId(): string { return this.$store ? this.$store.getters["domainId"] : ""; }
    get topicId(): string { return this.$store ? this.$store.getters["topicId"] : ""; }
    get commentAvailable(): boolean {
        if( this.hasFeature("deny-edit") == false ) return true; // 未対応組織なので許可
        return AclManager.getCreateCommentAcl( this.$store, this.domainId, this.topicId, this.id ) != "deny";
    }
    get reactionAvailable(): boolean {
        if( this.hasFeature("deny-edit") == false ) return true; // 未対応組織なので許可
        return AclManager.getCreateReactionAcl( this.$store, this.domainId, this.topicId, this.id ) != "deny";
    }

    blurEvent():void {
        this.focus = false;
    }

    focusEvent(): void {
        this.focus = true;
    }

    openModal( value: string ): void {
        const comment = this.comments.find( (m) => m.id === value );
        if(!comment) return;
        const param: PhotoStreamArgs = { files: comment.photos, messageId: this.id, commentId: comment.id };
        this.$root.$emit("open-photo-stream", param);
    }

    updateFile(files: (File | Attachment)[]): void {
        const fileList: File[] = [];
        files.map( file => {
            if(!AttachmentFactory.isAttachment(file)) {
                fileList.push(file)
            }
        })
        this.sendFiles = fileList;
    }

    dropFiles(files: FileList): void {
        const form = this.$refs.fileSelectionForm as FileSelectionForm;
        form.dropFiles(files);
    }

    async addComment (): Promise<void> {

        // 送信連打による連続送信を防止
        if( this.submitLock ) return;
        this.submitLock = true;

        const newId = uuidv4();
        const form = this.$refs.fileSelectionForm as FileSelectionForm;
        const promise = this.sendFiles.map(async(file) => {
            const result = await form.uploadCommentFile(file, this.id, newId);
            this.nowuploads++;
            return result;
        });

        this.uploadFiles = this.sendFiles.length;
        this.isUploading = true;
        const files = await Promise.all(promise)
        .catch( err => {
            console.log(err);
            this.$root.$emit("show-error-modal", { msg: "添付ファイルの送信に失敗しました。", afterProcess: ()=>{return} })
            return undefined;
        })
        .finally( () => {
            this.isUploading = false;
            this.nowuploads = 0;
            form.resetFileList();
        });

        if( !files ) {
            this.target = null;
            this.submitLock = false;
            return;
        }
        const comment = Comment.create({
            id: newId,
            message: this.text,
            photos: files,
        });

        const obj = { comment: comment, messageId: this.id };

        if( this.$root && this.$root.$emit ) {
            this.$root.$emit( 'on-comment-create', obj );
        }

        if( this.$emit ) {
            this.$emit( 'on-comment-create', obj );
        }

        this.text = '';
        this.sendFiles = [];
        this.target = null;
        this.submitLock = false;
        this.allow_submit = false;
    }

    enableSubmit():void {
        this.allow_submit = true;
    }

    async enterSubmit(): Promise<void> {
        if(!this.allow_submit) return;

        // enter送信用
        if( this.contentState ) {
            await this.addComment();
        }
    }

    toEditComment(value: string): void {
        const comment = this.comments.find( (c) => c.id === value );
        if(comment) {
            const param = {
                commentId: comment.id,
                messageId: this.id,
                message: comment.message,
                photos: comment.photos
            }
            // MessageList の CommenteditModal へ イベントを飛ばす
            this.$root.$emit("open-comment-edit-modal", param);
        }
    }

    toDeleteComment(value: string): void {
        const comment = this.comments.find( (c) => c.id === value );
        if(comment) {
            this.deleteId = comment.id;
            this.$bvModal.show(this.deleteModalId);
        }
    }

    /** 指定されたフィーチャーを持つかどうかを調べる */
    hasFeature( keyword: FeatureName ): boolean { return allowFeature( keyword, this.$store); }

    /******************************************************************/
    // Lifecycle Hook

    mounted(): void {
        // scroll処理(コメントID指定の場合のみ)
        if( this.$route ) {
            const msgId = this.$route.params.messageId
            if( msgId && msgId == this.id ) {
                const cmtId = this.$route.params.commentId
                if( cmtId ) this.scrollJumpFlag = true;   // スクロールジャンプ有りにする
            }
        }
    }
}

