import { AttachmentPolicy, AuthorityGroupPolicy, DownloadPolicy } from "./API";
import type { AttachmentFileTypes, DownloadDeviceTypes } from "./suppport-attachment-types";

export const RestrictType = {
    omit: 0,
    permit: 1,
    direct: 2,
} as const;

export type RestrictType = typeof RestrictType[keyof typeof RestrictType];
export type CustomFileType = keyof AttachmentFileTypes
export type CustomDeviceType = keyof DownloadDeviceTypes;

export type DomainSettings = {
    __typename: "DomainSettings",
    id: string,
    createTopicAuthorityGroupPolicy?: AuthorityGroupPolicy | null,
    updateTopicAuthorityGroupPolicy?: AuthorityGroupPolicy | null,
    attachmentPolicy: AttachmentPolicy,
    attachmentFileType: AttachmentFileTypes,
    downloadPolicy: DownloadPolicy,
    downloadDeviceType?: DownloadDeviceTypes,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
}