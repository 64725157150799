import { API, graphqlOperation } from "aws-amplify";
import { deleteDomainSettings } from "@/graphql/mutations";
import { Domain } from "@/model"
import { ServerApiAccess } from "@/server-api-access"
import {
    AttachmentPolicy,
    AuthorityGroupPolicy,
    DownloadPolicy,
    DeleteDomainSettingsMutationVariables,
    DeleteDomainSettingsMutation,
} from "@/API"
import { AttachmentFileTypes, DownloadDeviceTypes } from "@/suppport-attachment-types";
import { DomainSettings } from "@/domain-setting-types";

export default class DomainDao {
    private constructor(){
        /* インスタンス化させない */
    }

    /**
     * 組織設定を取得する(単一)
     * @param domainId
     * @returns
     */
    public static async read( domainId: string ): Promise<Domain> {
        ServerApiAccess.logging( "domain-settings.read", { domainId: domainId } );
        const api = new ServerApiAccess();
        const result = await api.getDomainSettings(domainId);
        return result;
    }


    /**
     * 組織設定の作成/更新
     * @param domain 
     * @returns 
     */
    public static async upsert( domain: Domain ): Promise<Domain|undefined> {
        const domainSettings: Partial<DomainSettings> = {
            __typename: "DomainSettings",
            id: `${domain.domainId}`,
            createTopicAuthorityGroupPolicy: domain.createTopicAuthorityGroupPolicy as AuthorityGroupPolicy,
            updateTopicAuthorityGroupPolicy: domain.updateTopicAuthorityGroupPolicy as AuthorityGroupPolicy,
            attachmentPolicy: domain.attachmentPolicy as AttachmentPolicy,
            attachmentFileType: domain.attachmentFileType as AttachmentFileTypes,
            downloadPolicy: domain.downloadPolicy as DownloadPolicy,
            downloadDeviceType: domain.downloadDeviceType as DownloadDeviceTypes
        }
        ServerApiAccess.logging( "domain-settings.upsert", domainSettings );   // logging
        const api = new ServerApiAccess();
        const result = await api.upsertDomainSettings(domainSettings);
        if( result ) {
            return Domain.createFrom( result );
        } else {
            return undefined;
        }
    }

    public static async delete( domain: Domain ): Promise<Domain[]> {
        const variables: DeleteDomainSettingsMutationVariables = {
            input: {
                id: domain.domainId,
            }
        }
        ServerApiAccess.logging( "domain-settings.delete", variables.input );    // logging
        const op = graphqlOperation( deleteDomainSettings, variables );
        try {
            await ( API.graphql( op ) as Promise<{data: DeleteDomainSettingsMutation}> );
        } catch( error ) {
            console.error( "DomainSettings Delete Error:%O", error );
        }

        // 未実装
        return [];
    }
}
