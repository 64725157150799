







import { IndexdDB } from '@/indexddb';
import { Component, Vue } from 'vue-property-decorator';

import AppAuth from "../aws-config";

@Component({
  components: {
  },
})
export default class Logout extends Vue {
    async created(): Promise<void> {
        await IndexdDB.dropDB();
        await AppAuth.signOut();
        this.$router.replace("/", () => {
            // "/" → "/" での NavigationDuplicated が出る場合があるが、無視する
        });
    }
}
