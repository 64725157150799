























































































import AppAuth from "../aws-config";
import { PRODUCT_STAGE } from "../aws-config";
import type { PROVIDER } from "../aws-config";
import Loading from "../components/loading/Loading.vue"
import { isMobile, osType, OS_TYPE } from "../mobile";
import mobileLoginWorkflow from "./mobile-login-workflow";

import { Component, Vue } from "vue-property-decorator";
import { LoadingManager } from "../components/loading/loading-manager";
import sentry from "@/sentry";

declare global {
    // iOS向け関数 https://lisb.myjetbrains.com/youtrack/issue/dxflow-13
    export function DAJSHGetDomainId(): void;
    export interface Window {
        webkit: {
            messageHandlers: {
                DANHAuthenticateWebApp: {
                    postMessage: ( authUrl: string ) => void;
                },
                DANHCloseWebView: {
                    postMessage: ( dummy: string ) => void;
                },
            },
        },
        directJsApi: {
            signin: ( authUrl: string ) => void;
            setCurrentDomainId: ( domainId: string ) => void;
            closeWindow: () => void;
            hideToolbar: () => void;
        }
    }
}

enum LoadingMngKey {
    LOGIN_COGNITO = "loginCognito",
    LOGIN_MOBILE_REDIRECT = "loginMobile",
}

@Component({ components: { Loading } })
export default class Login extends Vue {
    public readonly name = "login";

    status = "";
    message_text = "";
    loginContinue: boolean = false;
    stage = PRODUCT_STAGE;
    useLoadingView = true;  // true: ログイン画面を出さず、ロード中画面にする
    count = 0; // リトライのカウント数

    get loginRestrictDesc(): string {
        return (this.$route.query?.desc || "") as string;
    }

    created(): void {
        this.loginContinue = this.$route.path.indexOf("login-continue") != -1;
    }

    async mounted(): Promise<void> {
        try {
            const msg = 'ログイン中...';
            this.sendLoadingManager( LoadingMngKey.LOGIN_COGNITO, msg );
            await AppAuth.authenticatedUser();
            this.status = msg
        } catch( err ) {
            if( this.loginContinue ) {
                this.status = 'ログイン中...'
                this.sendLoadingManager( LoadingMngKey.LOGIN_COGNITO, this.status );
                let provider: PROVIDER;
                switch( this.$route.path ) {
                    case "/login-continue":     provider = "direct"; break;
                    case "/login-continue-stg": provider = "direct-staging"; break;
                    case "/login-continue-dev": provider = "direct-dev"; break;
                    default:                    provider = "direct"; break;
                }
                try {
                    await AppAuth.signIn( provider );
                } finally {
                    this.stopLoadingManager( LoadingMngKey.LOGIN_COGNITO );
                }
            } else {
                // Cognito側の認証エラー

                const msg = 'ログイン中...';
                this.sendLoadingManager( LoadingMngKey.LOGIN_COGNITO, msg );

                // mobile判定を$routeからすると失敗するパターンがあるため vuex 経由
                // ＊認可callback後など
                const device = this.$store.getters["device"];
                if( isMobile( device ) ) { // スマホは別処理
                    const type = osType( device );
                    await this.signIn( type );
                } else {                        // Web版
                    let provider: PROVIDER | "" = "";
                    switch( this.stage ) {
                        case "PRD": provider = "direct"; break;
                        case "STG": provider = "direct-staging"; break;
                        case "DEV": provider = "direct-dev"; break;
                    }
                    if( provider ) {
                        await AppAuth.signIn( provider );
                        this.status = msg;
                    }
                }
            }
        } finally {
            this.count = 0;
            this.stopLoadingManager( LoadingMngKey.LOGIN_COGNITO );
        }
    }

    async signIn( provider: PROVIDER | OS_TYPE ): Promise<void> {
        try {
            switch( provider ) {
                case "ios":     await this.mobileSignInWorkflow( "direct-mobile", "ios" ); break;
                case "android": await this.mobileSignInWorkflow( "direct-mobile", "and" ); break;
                default:        await AppAuth.signIn( provider as PROVIDER ); break;
            }
            this.status = 'ログイン中...';
        } catch( err ) {
            sentry.sendSentryError( err )
            return;
        }
    }

    // モバイル向けサインインワークフロー
    async mobileSignInWorkflow( provider: PROVIDER, option: "ios" | "and" ): Promise<void> {
        // dxflow:2429 モバイルログイン処理のタイミングを、画面ロード後にずらす(iOSで画面ロード中にページ読み込みが中止されたというエラーが出るため）
        this.$nextTick( () => {
            try {
                mobileLoginWorkflow( provider, option, (msg: string) => {
                    this.sendLoadingManager( LoadingMngKey.LOGIN_MOBILE_REDIRECT, msg );
                    this.status = msg;
                } )
            } catch( error ) {
                sentry.sendSentryError( error )
            } finally {
                this.stopLoadingManager( LoadingMngKey.LOGIN_MOBILE_REDIRECT );
            }
        })
    }

    async signOut(): Promise<void> {
        const url = await AppAuth.signOut();
        if( url ) { window.location.href = url; }
    }

    private sendLoadingManager( category: LoadingMngKey, msg: string ) {
        if( !this.useLoadingView ) LoadingManager.start( category, msg );
    }

    private stopLoadingManager( category: LoadingMngKey ) {
        if( !this.useLoadingView ) LoadingManager.stop( category );
    }
}
