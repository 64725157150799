var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isMobile
    ? _c("b-modal", {
        attrs: {
          id: "modal-topic",
          "hide-header": "",
          "hide-footer": "",
          centered: "",
          scrollable: "",
          "no-close-on-backdrop": "",
          size: "xl",
          "body-class": "d-flex flex-column max-vh-100 position-relative"
        },
        on: { show: _vm.resetParam },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function(ref) {
                var close = ref.close
                return [
                  _c(
                    "div",
                    {
                      on: {
                        dragover: function($event) {
                          $event.preventDefault()
                          _vm.drag = true
                        },
                        dragleave: function($event) {
                          $event.preventDefault()
                          _vm.drag = false
                        },
                        drop: function($event) {
                          $event.preventDefault()
                          _vm.drag = false
                        }
                      }
                    },
                    [
                      _c("drag-area-overlay", {
                        attrs: { drag: _vm.drag },
                        on: { dropFiles: _vm.dropFiles }
                      }),
                      _c(
                        "b-row",
                        { staticClass: "d-flex m-1 justify-content-end" },
                        [
                          _c(
                            "div",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.showEditDiscardAlert(close)
                                }
                              }
                            },
                            [_c("b-icon-x", { staticClass: "x-btn" })],
                            1
                          )
                        ]
                      ),
                      _c(
                        "b-row",
                        { staticClass: "d-flex" },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "topic-title-label",
                                    label: "タイトル",
                                    "label-for": "topic-title-input",
                                    "label-align": "left"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "topic-title-input",
                                      placeholder: "話題のタイトルを入力",
                                      "aria-describedby": "title-feedback",
                                      autofocus: "",
                                      required: ""
                                    },
                                    on: {
                                      change: _vm.updateComplteButtonDisabled,
                                      update: function($event) {
                                        return _vm.changeEditMode(_vm.editMode)
                                      }
                                    },
                                    model: {
                                      value: _vm.titleSource,
                                      callback: function($$v) {
                                        _vm.titleSource = $$v
                                      },
                                      expression: "titleSource"
                                    }
                                  }),
                                  _c(
                                    "b-form-text",
                                    {
                                      class: _vm.validateTitle
                                        ? "success"
                                        : "warning",
                                      staticStyle: { display: "flex" },
                                      attrs: { id: "title-feedback" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.validTitleMessage) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "topic-description-label",
                                    label: "説明",
                                    "label-for": "topic-description-input",
                                    "label-align": "left"
                                  }
                                },
                                [
                                  _c("b-form-textarea", {
                                    attrs: {
                                      id: "topic-description-input",
                                      rows: "3",
                                      "aria-describedby": "desc-feedback"
                                    },
                                    on: {
                                      change: _vm.updateComplteButtonDisabled,
                                      update: function($event) {
                                        return _vm.changeEditMode(_vm.editMode)
                                      }
                                    },
                                    model: {
                                      value: _vm.descSource,
                                      callback: function($$v) {
                                        _vm.descSource = $$v
                                      },
                                      expression: "descSource"
                                    }
                                  }),
                                  _c(
                                    "b-form-text",
                                    {
                                      class: _vm.validateDesc
                                        ? "success"
                                        : "warning",
                                      staticStyle: { display: "flex" },
                                      attrs: { id: "desc-feedback" }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.validDescMessage) + " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm.isAdmin && _vm.allowFeature("acl-dialog")
                                ? _c(
                                    "b-button",
                                    { on: { click: _vm.openAclEditModal } },
                                    [_vm._v("閲覧権限設定")]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("file-selection-form", {
                                ref: "fileSelectionForm",
                                attrs: {
                                  contentType: "TOPIC",
                                  label: "サムネイル",
                                  multiple: false,
                                  photos: _vm.photos,
                                  topicId: _vm.topicId,
                                  allow_attachment_type:
                                    _vm.allow_attachment_type
                                },
                                on: { onUpdateThumb: _vm.updateFile }
                              }),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "topic-category-select-one-label",
                                    label: "カテゴリー",
                                    "label-align": "left"
                                  }
                                },
                                [
                                  _c("category-edit", {
                                    attrs: {
                                      id: "category-edit",
                                      categories: _vm.categories,
                                      isMobile: _vm.isMobile,
                                      domainId: _vm.domainId,
                                      me: _vm.me,
                                      attachedCategories: _vm.categorySource
                                        ? [_vm.categorySource]
                                        : []
                                    },
                                    on: {
                                      onCloseCategoryFilterDialog:
                                        _vm.onCategorySelected
                                    }
                                  }),
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-modal",
                                          rawName: "v-b-modal.category-edit",
                                          modifiers: { "category-edit": true }
                                        }
                                      ],
                                      class:
                                        "category-button " +
                                        _vm.selectedCategoryColor,
                                      attrs: {
                                        variant: _vm.categoryButtonVariant,
                                        "aria-describedby": "category-feedback"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "category-button-label"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.selectedCategoryTitle)
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm.categoryButtonVariant == "danger"
                                    ? _c(
                                        "b-form-text",
                                        {
                                          staticClass: "warning",
                                          staticStyle: { display: "flex" },
                                          attrs: { id: "category-feedback" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                "カテゴリーは必須項目です"
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "topic-category-pinned-label",
                                    label: "",
                                    "label-for": "topic-category-pinned",
                                    "label-align": "left"
                                  }
                                },
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      attrs: { id: "topic-category-pinned" },
                                      on: {
                                        input: function($event) {
                                          return _vm.changeEditMode(
                                            _vm.editMode
                                          )
                                        },
                                        change: _vm.onChangePinnedSource
                                      },
                                      model: {
                                        value: _vm.pinnedSource,
                                        callback: function($$v) {
                                          _vm.pinnedSource = $$v
                                        },
                                        expression: "pinnedSource"
                                      }
                                    },
                                    [_vm._v("閲覧者の画面で上部に固定表示する")]
                                  ),
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      staticClass: "ml-4",
                                      attrs: {
                                        id: "topic-notification",
                                        disabled: !_vm.pinnedSource
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.changeEditMode(
                                            _vm.editMode
                                          )
                                        }
                                      },
                                      model: {
                                        value:
                                          _vm.pinnedSourceOptions.notification,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.pinnedSourceOptions,
                                            "notification",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "pinnedSourceOptions.notification"
                                      }
                                    },
                                    [_vm._v("新規投稿されると全員に通知する")]
                                  ),
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      attrs: { id: "allow-guest-checkbox" },
                                      model: {
                                        value: _vm.allowGuestSource,
                                        callback: function($$v) {
                                          _vm.allowGuestSource = $$v
                                        },
                                        expression: "allowGuestSource"
                                      }
                                    },
                                    [_vm._v("ゲストにも閲覧を許可する")]
                                  ),
                                  _vm.hasFeature("deny-edit")
                                    ? _c(
                                        "b-form-checkbox",
                                        {
                                          attrs: { id: "allow-write-others" },
                                          model: {
                                            value: _vm.allowWriteOthersSource,
                                            callback: function($$v) {
                                              _vm.allowWriteOthersSource = $$v
                                            },
                                            expression: "allowWriteOthersSource"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "作成者以外の投稿(ゲストを除く)・コメント・いいねを許可する"
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                {
                                  attrs: {
                                    "align-h": "between",
                                    "no-gutters": ""
                                  }
                                },
                                [
                                  _c("cancel", {
                                    staticClass: "col-5",
                                    attrs: { label: "キャンセル" },
                                    on: {
                                      cancel: function($event) {
                                        return _vm.showEditDiscardAlert(
                                          _vm.onEditCancel
                                        )
                                      }
                                    }
                                  }),
                                  _c("confirm", {
                                    staticClass: "col-5",
                                    attrs: {
                                      label: "保存",
                                      disabled: _vm.completeButtonDisabled
                                    },
                                    on: { confirm: _vm.onEditComplete }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ],
          null,
          false,
          3965420566
        )
      })
    : _c(
        "b-container",
        { staticClass: "p-0", attrs: { fluid: "" } },
        [
          _c("navigation-bar", {
            attrs: {
              fluid: "",
              cancel: "true",
              items: _vm.items,
              title: _vm.navTitle,
              fetchUserInfo: false
            },
            on: {
              "on-cancel": function($event) {
                return _vm.showEditDiscardAlert(_vm.onEditCancel)
              }
            }
          }),
          _c(
            "b-container",
            { staticClass: "my-3 topic-edit-body", attrs: { fluid: "" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "topic-title-label",
                    label: "タイトル",
                    "label-for": "topic-title-input",
                    "label-align": "left"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "topic-title-input",
                      placeholder: "話題のタイトルを入力",
                      "aria-describedby": "title-feedback",
                      autofocus: "",
                      required: ""
                    },
                    on: {
                      change: _vm.updateComplteButtonDisabled,
                      update: function($event) {
                        return _vm.changeEditMode(_vm.editMode)
                      }
                    },
                    model: {
                      value: _vm.titleSource,
                      callback: function($$v) {
                        _vm.titleSource = $$v
                      },
                      expression: "titleSource"
                    }
                  }),
                  _c(
                    "b-form-text",
                    {
                      class: _vm.validateTitle ? "success" : "warning",
                      staticStyle: { display: "flex" },
                      attrs: { id: "title-feedback" }
                    },
                    [_vm._v(" " + _vm._s(_vm.validTitleMessage) + " ")]
                  )
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "topic-description-label",
                    label: "説明",
                    "label-for": "topic-description-input",
                    "label-align": "left"
                  }
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "topic-description-input",
                      rows: "3",
                      "aria-describedby": "desc-feedback"
                    },
                    on: {
                      change: _vm.updateComplteButtonDisabled,
                      update: function($event) {
                        return _vm.changeEditMode(_vm.editMode)
                      }
                    },
                    model: {
                      value: _vm.descSource,
                      callback: function($$v) {
                        _vm.descSource = $$v
                      },
                      expression: "descSource"
                    }
                  }),
                  _c(
                    "b-form-text",
                    {
                      class: _vm.validateDesc ? "success" : "warning",
                      staticStyle: { display: "flex" },
                      attrs: { id: "desc-feedback" }
                    },
                    [_vm._v(" " + _vm._s(_vm.validDescMessage) + " ")]
                  )
                ],
                1
              ),
              _c("file-selection-form", {
                ref: "fileSelectionForm",
                attrs: {
                  contentType: "TOPIC",
                  label: "サムネイル",
                  multiple: false,
                  photos: _vm.photos,
                  topicId: _vm.topicId,
                  allow_attachment_type: _vm.allow_attachment_type
                },
                on: { onUpdateThumb: _vm.updateFile }
              }),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "topic-category-select-one-label",
                    label: "カテゴリー",
                    "label-for": "topic-category-select-one",
                    "label-align": "left"
                  }
                },
                [
                  _c("category-edit", {
                    attrs: {
                      id: "category-edit",
                      categories: _vm.categories,
                      isMobile: _vm.isMobile,
                      domainId: _vm.domainId,
                      me: _vm.me,
                      attachedCategories: _vm.categorySource
                        ? [_vm.categorySource]
                        : []
                    },
                    on: { onCloseCategoryFilterDialog: _vm.onCategorySelected }
                  }),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.category-edit",
                          modifiers: { "category-edit": true }
                        }
                      ],
                      class: "category-button " + _vm.selectedCategoryColor,
                      attrs: {
                        variant: _vm.categoryButtonVariant,
                        "aria-describedby": "category-feedback"
                      }
                    },
                    [
                      _c("div", { staticClass: "category-button-label" }, [
                        _vm._v(_vm._s(_vm.selectedCategoryTitle))
                      ])
                    ]
                  ),
                  _vm.categoryButtonVariant == "danger"
                    ? _c(
                        "b-form-text",
                        {
                          staticClass: "warning",
                          staticStyle: { display: "flex" },
                          attrs: { id: "category-feedback" }
                        },
                        [_vm._v(" " + _vm._s("カテゴリーは必須項目です") + " ")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "topic-category-pinned-label",
                    label: "",
                    "label-for": "topic-category-pinned",
                    "label-align": "left"
                  }
                },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { id: "topic-category-pinned" },
                      on: {
                        input: function($event) {
                          return _vm.changeEditMode(_vm.editMode)
                        },
                        change: _vm.onChangePinnedSource
                      },
                      model: {
                        value: _vm.pinnedSource,
                        callback: function($$v) {
                          _vm.pinnedSource = $$v
                        },
                        expression: "pinnedSource"
                      }
                    },
                    [_vm._v("閲覧者の画面で上部に固定表示する")]
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "ml-4",
                      attrs: {
                        id: "topic-notification",
                        disabled: !_vm.pinnedSource
                      },
                      on: {
                        input: function($event) {
                          return _vm.changeEditMode(_vm.editMode)
                        }
                      },
                      model: {
                        value: _vm.pinnedSourceOptions.notification,
                        callback: function($$v) {
                          _vm.$set(_vm.pinnedSourceOptions, "notification", $$v)
                        },
                        expression: "pinnedSourceOptions.notification"
                      }
                    },
                    [_vm._v("新規投稿されると全員に通知する")]
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { id: "allow-guest-checkbox" },
                      model: {
                        value: _vm.allowGuestSource,
                        callback: function($$v) {
                          _vm.allowGuestSource = $$v
                        },
                        expression: "allowGuestSource"
                      }
                    },
                    [_vm._v("ゲストにも閲覧を許可する")]
                  ),
                  _vm.hasFeature("deny-edit")
                    ? _c(
                        "b-form-checkbox",
                        {
                          attrs: { id: "allow-write-others" },
                          model: {
                            value: _vm.allowWriteOthersSource,
                            callback: function($$v) {
                              _vm.allowWriteOthersSource = $$v
                            },
                            expression: "allowWriteOthersSource"
                          }
                        },
                        [
                          _vm._v(
                            "作成者以外の投稿(ゲストを除く)・コメント・いいねを許可する"
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }