var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isBlocked
    ? _c(
        "b-container",
        { staticClass: "message-list", attrs: { fluid: "", id: "msglist" } },
        [
          _c("div", { staticClass: "deleted-message" }, [
            _vm._v(
              "話題が存在しません。削除されたか、もしくは閲覧権がありません"
            )
          ])
        ]
      )
    : _c(
        "b-container",
        { staticClass: "message-list", attrs: { fluid: "", id: "msglist" } },
        [
          _vm.scrollJumpFlag
            ? _c("message-page-header", {
                attrs: {
                  titleLabel: _vm.titleLabel,
                  domainId: _vm.domainId,
                  topicId: _vm.topicId
                }
              })
            : _c(
                "b-row",
                { ref: "topicHeader", staticClass: "message-topic-header" },
                [
                  _c("div", { staticClass: "left-option" }, [
                    _vm.pinned
                      ? _c(
                          "div",
                          { staticClass: "left-pinned" },
                          [_c("icon-pinned", { staticClass: "pinned" })],
                          1
                        )
                      : _vm._e(),
                    !!_vm.notification
                      ? _c("span", {
                          staticClass:
                            "dicon dicon-speaker-on topic-notification left-pinned"
                        })
                      : _vm._e()
                  ]),
                  _c(
                    "div",
                    { staticClass: "message-topic-icon" },
                    [
                      _c("profile-icon", {
                        staticClass:
                          "topic-list-item-icon rounded-circle m-auto",
                        attrs: {
                          userName: _vm.title,
                          image: _vm.icon,
                          topicId: _vm.topicId,
                          size: "72",
                          fontSize: "32pt"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "message-topic-body" },
                    [
                      _c(
                        "b-container",
                        {
                          staticClass:
                            "d-flex flex-column category-container px-0"
                        },
                        [
                          _c(
                            "b-row",
                            {
                              staticClass:
                                "justify-content-center align-items-center",
                              attrs: { "no-gutters": "" }
                            },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "d-inline-flex align-items-center text-nowrap"
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      ref: "topic-category-title",
                                      class:
                                        "badge badge-secondary category " +
                                        _vm.categoryColor
                                    },
                                    [_vm._v(_vm._s(_vm.categoryLabel))]
                                  ),
                                  _vm.pinned
                                    ? _c(
                                        "span",
                                        { staticClass: "right-pinned mr-2" },
                                        [
                                          _c("icon-pinned", {
                                            staticClass: "pinned"
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("b-tooltip", {
                                    attrs: {
                                      target: function() {
                                        return _vm.$refs["topic-category-title"]
                                      },
                                      title: _vm.categoryLabel
                                    },
                                    on: {
                                      show: function($event) {
                                        return _vm.onTooltipShow(
                                          $event,
                                          "topic-category-title"
                                        )
                                      }
                                    }
                                  }),
                                  !!_vm.notification
                                    ? _c("span", {
                                        staticClass:
                                          "dicon dicon-speaker-on topic-notification right-pinned mr-2"
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm.hasFeature("topic-owner-icon")
                                ? _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "d-inline-flex align-items-center text-nowrap"
                                    },
                                    [
                                      _c("profile-icon", {
                                        staticClass:
                                          "rounded-circle contributor-icon",
                                        attrs: {
                                          userName: _vm.contributor.name,
                                          id: _vm.contributorId,
                                          image: _vm.contributorIcon,
                                          size: "25"
                                        }
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "contributor" },
                                        [_vm._v(_vm._s(_vm.contributor.name))]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          ref: "message-topic-title",
                          staticClass: "message-topic-title"
                        },
                        [_vm._v(_vm._s(_vm.titleLabel))]
                      ),
                      _c("div", {
                        staticClass: "message-topic-desc",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$sanitize(_vm.descSource, {
                              allowedAttributes: {
                                a: ["href", "name", "target", "rel"]
                              }
                            })
                          )
                        }
                      }),
                      _c("b-tooltip", {
                        attrs: {
                          target: function() {
                            return _vm.$refs["message-topic-title"]
                          },
                          title: _vm.titleLabel
                        },
                        on: {
                          show: function($event) {
                            return _vm.onTooltipShow(
                              $event,
                              "message-topic-title"
                            )
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "right-option" }, [
                    _c(
                      "div",
                      { staticClass: "mr-2" },
                      [
                        _c(
                          "b-iconstack",
                          {
                            staticClass: "three-dots",
                            attrs: {
                              id: _vm.topicEditId,
                              "font-scale": "1",
                              tabindex: "0"
                            }
                          },
                          [_c("b-icon-three-dots")],
                          1
                        ),
                        _c(
                          "b-popover",
                          {
                            ref: "popover",
                            attrs: {
                              target: _vm.topicEditId,
                              placement: "bottom",
                              triggers: "focus"
                            }
                          },
                          [
                            _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass: "edit-menu-item",
                                  on: { click: _vm.onUrlCopyTopic }
                                },
                                [_vm._v("URLコピー")]
                              ),
                              _vm.topicUpdatable
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "edit-menu-item",
                                      on: { click: _vm.onTopicEditMessage }
                                    },
                                    [_vm._v("編集")]
                                  )
                                : _vm._e(),
                              _vm.topicDeletable
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "edit-menu-item",
                                      on: { click: _vm.onTopicDeleteMessage }
                                    },
                                    [_vm._v("削除")]
                                  )
                                : _vm._e()
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "mr-2" }, [
                      _vm.topicStarFlag
                        ? _c(
                            "div",
                            { on: { click: _vm.onTopicStarClick } },
                            [
                              _c(
                                "b-iconstack",
                                {
                                  staticClass: "star",
                                  attrs: { "font-scale": "1" }
                                },
                                [
                                  _c("b-icon-star-fill", {
                                    staticClass: "has-star"
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { on: { click: _vm.onTopicStarClick } },
                            [
                              _c(
                                "b-iconstack",
                                {
                                  staticClass: "star",
                                  attrs: { "font-scale": "1" }
                                },
                                [
                                  _c("b-icon-star-fill", {
                                    staticClass: "not-star"
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "text-align": "center",
                            "font-size": "12px"
                          }
                        },
                        [_vm._v(_vm._s(_vm.topicStarCount))]
                      )
                    ])
                  ])
                ]
              ),
          !(_vm.messageList || []).length
            ? _c("div", { staticClass: "no-messages" }, [
                _vm._v(_vm._s(_vm.noItemsError))
              ])
            : _vm._e(),
          _vm._l(_vm.messageList, function(message) {
            return _c(
              "b-row",
              { key: message.id },
              [
                !message.deleted
                  ? _c("message-list-item", {
                      attrs: {
                        id: message.id,
                        domainId: message.domainId,
                        topicId: message.topicId,
                        title: message.title,
                        message: message.message,
                        star: message.star,
                        pinned: message.pinned,
                        icon: message.icon,
                        owner: message.owner,
                        updatedAt: message.updatedAt,
                        comments: message.comments,
                        photos: message.photos,
                        likes: message.likes,
                        like: message.like,
                        users: _vm.users,
                        viewer: _vm.viewer,
                        allow_attachment_type: _vm.allow_attachment_type,
                        acl: message.acl,
                        baseSort: _vm.baseSort
                      },
                      on: {
                        openModal: _vm.openModal,
                        toEditMessage: _vm.toEditMessage,
                        toDeleteMessage: _vm.toDeleteMessage
                      }
                    })
                  : _c("div", { staticClass: "deleted-message" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.userName(message.deletedUser)) +
                          "により削除されました "
                      )
                    ]),
                _c("div", {
                  staticStyle: {
                    width: "100%",
                    height: "10px",
                    "background-color": "#F4F4F4"
                  }
                })
              ],
              1
            )
          }),
          (_vm.messageList || []).length
            ? _c(
                "infinite-loading",
                {
                  staticClass: "mx-auto",
                  attrs: { identifier: _vm.infiniteId },
                  on: { infinite: _vm.infiniteHandler }
                },
                [
                  _c("span", {
                    attrs: { slot: "no-results" },
                    slot: "no-results"
                  }),
                  _c("span", { attrs: { slot: "no-more" }, slot: "no-more" })
                ]
              )
            : _vm._e(),
          _c("delete-modal", {
            attrs: {
              id: "delete-modal",
              messageId: _vm.deleteId,
              viewer: _vm.viewer,
              type: "message"
            }
          }),
          _c("delete-modal", {
            attrs: {
              id: "delete-topic-modal",
              topicId: _vm.topicId,
              viewer: _vm.viewer,
              type: "topic"
            }
          }),
          _vm.creatable
            ? _c("fab", { on: { "fab-clicked": _vm.fabClicked } })
            : _vm._e(),
          _c(
            "topic-edit",
            _vm._b(
              { attrs: { id: "modal-topic", isMobile: false } },
              "topic-edit",
              _vm.topicEditParam,
              false
            )
          ),
          _c("message-edit-modal", {
            attrs: {
              id: "modal-message",
              type: _vm.type,
              domainId: _vm.domainId,
              topicId: _vm.topicId,
              messageId: _vm.modalId,
              title: _vm.modalTitle,
              message: _vm.modalMessage,
              photos: _vm.modalPhotos,
              allow_attachment_type: _vm.allow_attachment_type,
              acl: _vm.modalAcl,
              topicAcl: _vm.topicAcl.clone()
            },
            on: { interruptionViaModal: _vm.interruptionProcess }
          }),
          _c("comment-edit-modal", {
            attrs: {
              id: "modal-comment",
              commentId: _vm.commentEditModalParam.commentId,
              messageId: _vm.commentEditModalParam.messageId,
              message: _vm.commentEditModalParam.message,
              photos: _vm.commentEditModalParam.photos,
              shown: _vm.shownCommentEditModal,
              allow_attachment_type: _vm.allow_attachment_type
            },
            on: { interruptionComment: _vm.interruptionProcess }
          })
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }