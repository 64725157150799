import { User, Topic, Message, Comment } from "@/model";
import UserDao from "./user-dao";
import type { UserReadResult } from "./user-dao";
import { ActionContext, Module } from "vuex";
import { State } from "vuex-class";
import { DirectDomainType, IconReactionType } from "@/API";
import { DirectDomainMembersType } from "@/direct-restapi-types";
import Vue from "vue";
import { IndexedDB } from "@/indexeddb";

type State = { users: { [domainId: string]: User[] }, me: User, domainMembers: { [domainId: string]: DirectDomainMembersType } }
type RootState = { domainId: string, topicId: string, messageId: string };
type UserActionContext = ActionContext< State, RootState >;

interface Payload<T> {
    data: T;            // カテゴリー
}
type PayloadAddMe = {
    data: User,
    domainId: string,
};
type PayloadAdd = {
    data: User[],
    domainId: string,
};

async function setIndexedDB( state: State ): Promise<void> {
    await Promise.all( Object.keys(state.users).map( async( key ) => {
        await IndexedDB.set("USERS", key, state.users[key]);
    }))
}

/** 組織内のユーザー一覧を取得する */
const getDomainUsers = ( state: State, domainId: string ): User[] => {
    const result = (state.users[domainId] || [])
        .filter( u => {
            if( u.id == state.me.id ) return true;  // 自分は許可

            // 組織内かどうかチェック
            const index = u.domainIdList.findIndex( id => id == domainId );
            return 0 <= index;  // 組織外をフィルタリング
        })
        .map( u => {
            // 自分ならそのまま返す
            if( u.id == state.me.id ) return u;

            // 権限チェック（roleが取れ無い->members APIで取得できてない->権限外)
            const hasRole = u.getRoleId( domainId );
            if( hasRole ) return u;
            return User.createNotFoundUser(
                u.id,
                u.directId,
            )
        })
        || []
        ;
    return result;
}

const userModule: Module< State, RootState > = {
    namespaced: true,
    state: {
        users: {},
        me: User.createNotFoundUser("dummy"),
        domainMembers: {},
    } as State,
    getters: {
        /** ログインユーザー情報 */
        me: ( state: State ) => { 
            // localStorageから消す用
            // TOOD: localStorageからusers.meを消して必要なくなったら消す
            if( !( "isOwnerRole" in state.me ) ) {
                return User.create(state.me);
            } else {
                return state.me;
            }
        },

        /** ユーザーIDを指定して取得する */
        get: ( state: State ) => ( cognitoUserId: string, domainId: string ): User|undefined => {
            return (state.users[domainId] || []).find( user => user.id == cognitoUserId );
        },

        /** 組織内のユーザー一覧を取得する */
        getByDomainId: ( state: State ) => ( domainId: string ): User[] => {
            return getDomainUsers( state, domainId );
        },

        /** domain members API の結果を取得する */
        getDomainMembers: ( state: State ) => ( domainId: string ): DirectDomainMembersType => {
            return state.domainMembers[ domainId ] || { contents: [] };
        },
    },
    mutations: {

        setMe( state: State, payload: PayloadAddMe ): void {
            const me = Array.isArray( payload.data ) ? payload.data[0] : payload.data;
            state.me = me;
        },

        /**
         * Storeに追加する
         * @param state
         * @param payload
         * @returns
         */
        add( state: State, payload: PayloadAdd ): void {
            Vue.set(state.users, payload.domainId, payload.data);
        },

        setDomainMembers( state: State, payload: { domainId: string, domainMembers: DirectDomainMembersType } ) {
            // domainMembers APIの結果をstate更新
            Vue.set( state.domainMembers, payload.domainId, payload.domainMembers );
        },

    },

    actions: {
        /**
         * DBからユーザー一覧を取得してstoreに保存
         * @param data 取得するドメインID
         */
        async fetch( { dispatch, commit, state }: UserActionContext, payload: Payload<string|undefined> ): Promise<void> {
            const domainId = payload.data || "";
            if( !domainId ) {
                console.log("fetch user data must set domainid")
                return;
            }
            const users_by_api = await UserDao.read( domainId );
            commit( "add", { data: users_by_api, domainId: domainId } );

            await setIndexedDB( state );
        },

        /**
         * 自身の情報を取得する
         * @param id: ログインユーザーのcognitoUserId
         */
        async fetchMe( { commit, getters }: UserActionContext ): Promise<boolean> {
            try {
                const me = await UserDao.getMe();
                if( me ) {
                    // storeに反映
                    const origin = getters.me as User;
                    origin.override( me );
                    commit( 'setMe', { data: origin } );
                }
                return !!me;
            } catch( error ) {
                console.error( error );
                return false;
            }
        },

        /** localStorageのusers/me情報をstoreへ反映させる用 */
        async setMe( { commit }, payload: User ): Promise<void> {
            commit( 'setMe', { data: payload } );
        },

        /** REST-APIの結果 domainMemmbersをセット */
        async setDomainMembers( { commit }, payload: { domainId: string, domainMembers: DirectDomainMembersType }): Promise<void> {
            commit( "setDomainMembers", payload);
        },

        async restore({ state }): Promise<void> {
            const users = await IndexedDB.getAll("USERS");
            if( users ) {
                state.users = users;
            }
        },
    }
}

export default userModule;
