




































































import Cancel from "@/components/button/Cancel.vue";
import Confirm from "@/components/button/Confirm.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { SORT_TYPE } from "@/store";

@Component({
    components: {
        Cancel,
        Confirm
    }
})
export default class SortCommentSetting extends Vue {
    name: string = "sort-comment-setting";

    SORT_COMMENT_SETTING_TITLE: string = "コメントの並び順設定";
    SORT_COMMENT_DESCRIPTION: string = "組織内のコメントの並び順の初期値の指定を行えます。\n各投稿内でコメントの並び順の変更を一時的に行えますが、話題を移動すると初期値に戻ります。";
    ASC_LABEL: string = "昇順";
    DESC_LABEL: string = "降順";

    sortValue: SORT_TYPE = "ASC"

    @Prop({ default: "ASC" }) originSortValue!: SORT_TYPE;
    @Watch('originSortValue') onCahange() {
        this.sortValue = this.originSortValue;
    }

    get isMobile(): boolean {
        return this.$store.getters["isMobile"];
    }

    get disabled(): boolean {
        return this.sortValue == this.originSortValue;
    }

    // storeのsortに合わせる
    initSortValue(): void {
        this.sortValue = this.originSortValue;
    }

    // sortValueの保存
    async saveSortValue( close: () => void ): Promise<void> {
        const domainId = this.$store.getters["domainId"];
        await this.$store.dispatch("setSortCommentSetting", { domainId: domainId, sort: this.sortValue });
        close();
    }
}
