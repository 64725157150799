import { render, staticRenderFns } from "./Cancel.vue?vue&type=template&id=96aece92&scoped=true&"
import script from "./Cancel.vue?vue&type=script&lang=ts&"
export * from "./Cancel.vue?vue&type=script&lang=ts&"
import style0 from "./Cancel.vue?vue&type=style&index=0&id=96aece92&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96aece92",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/m73takahiro.mori/dx-flow-2/dx-flow-open-channel/packages/open-channel/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('96aece92')) {
      api.createRecord('96aece92', component.options)
    } else {
      api.reload('96aece92', component.options)
    }
    module.hot.accept("./Cancel.vue?vue&type=template&id=96aece92&scoped=true&", function () {
      api.rerender('96aece92', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/button/Cancel.vue"
export default component.exports