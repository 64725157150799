







import { IndexedDB } from '@/indexeddb';
import { Component, Vue } from 'vue-property-decorator';

import AppAuth from "../aws-config";
import { ServerApiAccess } from '@/server-api-access';

@Component({
  components: {
  },
})
export default class Logout extends Vue {
    async created(): Promise<void> {
        // direct トークン取り消し処理
        const revokeTokenPromise = async () => {
            const api = new ServerApiAccess();
            await api.revokeToken();
        } 
        // DB削除
        const dropDBPromise = async () => {
            await IndexedDB.dropDB();
        }
        // サインアウト
        const signOutPromise = async () => {
            await AppAuth.signOut();
        }
        // 成功失敗に関わらず全て実行
        await Promise.allSettled([revokeTokenPromise(), dropDBPromise(), signOutPromise() ]);
        this.$router.replace("/", () => {
            // "/" → "/" での NavigationDuplicated が出る場合があるが、無視する
        });
    }
}
