





















































































import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";
import './comment-list-item.scss';
import moment from 'moment';
import sanitizeHTML from '../sanitize';
import AttachmentImg from './AttachmentImg.vue';
import TextProcessMixin from "./mixin/TextProcessMixin";
import ClipboardCopyMixin from "./mixin/ClipboardCopyMixin";
import ProfileIcon from "./ProfileIcon.vue";
import { User, Comment } from "../model";
Vue.prototype.$sanitize = sanitizeHTML;

import { /*BootstrapVue, BootstrapVueIcons,*/ BPopover } from "bootstrap-vue";
import type { IconReaction } from "../API";
import { IconReactionType } from "../API";
import { ReactionController } from "../model/reaction-controller";
import AclManager from "../model/acl-manager";
import { allowFeature } from "../direct-app-config";

// Vue.use(BootstrapVue);
// Vue.use(BootstrapVueIcons);

export type CommentListItemData = Comment;

@Component({
    mixins: [
        ClipboardCopyMixin, TextProcessMixin
    ],
    components: {
        AttachmentImg, ProfileIcon,
    }
})
export default class CommentListItem extends mixins( Vue, ClipboardCopyMixin, TextProcessMixin ){
    name: string = 'comment-list-item';

    // コメントID
    @Prop({ default: '', required: true })
    readonly id!: string;

    @Prop({ default: 'about:blank' })
    readonly icon!: string;

    // コメント作成者ID
    @Prop({ required: true })
    readonly user!: string;

    @Prop({ default: moment().toDate(), required: true })
    readonly createdAt!: Date;

    @Prop({ default: 'メッセージ', required: true })
    readonly message!: string;

    @Prop({ default: () => [] })
    readonly photos!: string[];

    @Prop({ default: '' })
    readonly search?: string;

    // 投稿ID
    @Prop({ default: '' })
    readonly messageId!: string;

    @Prop({ default: false })
    readonly deleted!: boolean;

    @Prop({ default: '' })
    readonly deletedUser!: string;

    // User関連
    @Prop({ default: () => [] }) readonly users!: User[]; // 組織内ユーザー情報
    @Prop({ default: () => ( User.createNotFoundUser() ) }) readonly viewer!: User; // ログインユーザ情報

    // Reaction
    @Prop({ default: undefined }) readonly like?: IconReaction;

    get classes (): { [selector: string]: boolean } {
        return {
            'comment-list-item': true
        }
    }

    get contents (): string {
        return this.highLightStr(this.message, this.search);
    }

    get timeStamp (): string {
        const dt = moment(this.createdAt)
        return dt.format('YYYY/MM/DD HH:mm')
    }

    get likeLabel (): string {
        return this.likeFlag ? 'いいねを取り消す' : 'いいね！';
    }

    get numberOfLike (): string {
        return `${this.likeCount}人`;
    }

    get edit(): boolean {
        return this.viewer.id == this.user;
    }

    // 作成者情報
    get contributor (): User {
        const user = this.users.find( u => u.id == this.user );
        return user || User.createNotFoundUser( this.user );
    }
    get contributorId(): string { return this.contributor.directId; }
    get contributorIcon(): string { return this.contributor.getProfileIconUrl(); }

    get deletedAlert(): string {
        const user = this.findUser( this.deletedUser );
        return `${user.name}により削除されました`
    }

    // Urlコピー
    onUrlCopyComment(): void {
        ( this.$refs.popover as BPopover).$emit('close'); // Close
        const domainId = this.$store.getters["domainId"];
        const topicId = this.$store.getters["topicId"];
        this.onUrlCopy({ domainId: domainId, topicId: topicId, messageId: this.messageId, commentId: this.id });
    }

    /** イイね！のフラグと数 */
    get domainId(): string { return this.$store ? this.$store.getters["domainId"] : ""; }
    get topicId(): string { return this.$store ? this.$store.getters["topicId"] : ""; }
    get likeFlag(): boolean { return this.like?.userIdList.includes( this.viewer.directId ) || false; }
    get likeCount(): number { return this.like?.userIdList.length || 0; }

    private findUser( directUserId: string ): User {
        const user = this.users.find( user => user.directId == directUserId );
        return user ? user : User.createNotFoundUser( directUserId );
    }

    // イイね！ボタンを押した時の動作
    onLikeButtonClick(): void { this.onReactionClicked( this.id, IconReactionType.LIKE ); }

    // 権限
    get reactionAvailable(): boolean {
        if( allowFeature( "deny-edit", this.$store ) == false ) return true;    // 未対応組織なので許可
        return AclManager.getCreateReactionAcl( this.$store, this.domainId, this.topicId, this.messageId, this.id ) != "deny";
    }

    openPhotoStream(): void {
        this.$emit('openModal', this.id)
    }

    onEditComment(): void {
        this.$emit('toEditComment', this.id);
    }

    onDeleteComment(): void {
        this.$emit('toDeleteComment', this.id);
    }

    /** Commentのリアクションボタンを押した時の処理 */
    onReactionClicked( commentId: string, type: IconReactionType ): void {
        const ctl = new ReactionController( this.$store );
        const target = ctl.findComment( this.messageId, commentId );
        ctl.onReactionClicked( target, type, this.viewer );
    }

    // いいね一覧表示
    showFavoriteList(): void {
        const userIdList = this.like?.userIdList || [];
        const favoriteUsers = userIdList.map( userId => {
            const user = this.users.find( u => u.directId == userId );
            return user ? user : User.createNotFoundUser( userId );
        })
        this.$root.$emit( 'on-favorite-modal', { userList: favoriteUsers, messageId: this.messageId, commentId: this.id });
    }

    // ユーザ名用ツールチップ
    onUserTooltipShow( bvEvent: Event ): void {
        const target = this.$refs[ 'comment-user' ];
        if( target instanceof HTMLElement ) {
            // webkit-line-clamp で省略された時、offsetHeight < scrollHeigt になる
            const oHeight = target.offsetHeight;
            const sHeight = target.scrollHeight;
            if( oHeight < sHeight ) return;
        }
        bvEvent.preventDefault(); // 出さない
    }
}
