var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.deleted
    ? _c("div", { staticClass: "deleted-comment" }, [
        _vm._v(" " + _vm._s(_vm.deletedAlert) + " ")
      ])
    : _c(
        "div",
        { class: _vm.classes, attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            { staticClass: "comment-body", attrs: { id: "cmt_" + _vm.id } },
            [
              _c(
                "div",
                [
                  _c("profile-icon", {
                    staticClass: "topic-list-item-icon rounded-circle m-auto",
                    attrs: {
                      userName: _vm.contributor.name,
                      id: _vm.contributorId,
                      image: _vm.contributorIcon,
                      size: "25"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "comment-center" }, [
                _c(
                  "div",
                  {
                    staticClass: "d-flex",
                    staticStyle: { "flex-direction": "row" }
                  },
                  [
                    _c("div", {
                      ref: "comment-user",
                      staticClass: "comment-user",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$sanitize(_vm.contributor.name, {
                            allowedAttributes: { span: ["class"] }
                          })
                        )
                      }
                    }),
                    _c("b-tooltip", {
                      attrs: {
                        target: function() {
                          return _vm.$refs["comment-user"]
                        },
                        title: _vm.contributor.name
                      },
                      on: { show: _vm.onUserTooltipShow }
                    }),
                    _c(
                      "div",
                      { staticClass: "comment-edit" },
                      [
                        !_vm.search
                          ? _c(
                              "b-iconstack",
                              {
                                staticClass: "three-dots",
                                attrs: {
                                  id: "edit-button-comment-" + _vm.id,
                                  "font-scale": "1",
                                  tabindex: "0"
                                }
                              },
                              [_c("b-icon-three-dots")],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "b-popover",
                          {
                            ref: "popover",
                            attrs: {
                              target: "edit-button-comment-" + _vm.id,
                              placement: "bottom",
                              triggers: "focus"
                            }
                          },
                          [
                            _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass: "edit-menu-item",
                                  on: { click: _vm.onUrlCopyComment }
                                },
                                [_vm._v("URLコピー")]
                              ),
                              _vm.reactionAvailable
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "edit-menu-item",
                                      on: { click: _vm.showFavoriteList }
                                    },
                                    [_vm._v("いいね！の一覧を表示する")]
                                  )
                                : _vm._e(),
                              _vm.edit
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "edit-menu-item",
                                      on: { click: _vm.onEditComment }
                                    },
                                    [_vm._v("編集")]
                                  )
                                : _vm._e(),
                              _vm.edit
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "edit-menu-item",
                                      on: { click: _vm.onDeleteComment }
                                    },
                                    [_vm._v("削除")]
                                  )
                                : _vm._e()
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "comment-content" }, [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$sanitize(_vm.contents, {
                          allowedAttributes: {
                            span: ["class"],
                            a: ["href", "name", "target", "rel"]
                          }
                        })
                      )
                    }
                  })
                ]),
                _c("div", { staticClass: "comment-info" }, [
                  _c("div", { staticClass: "mr-2" }, [
                    _vm._v(_vm._s(_vm.timeStamp))
                  ]),
                  !_vm.search && _vm.reactionAvailable
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "font-weight": "bolder",
                            cursor: "pointer"
                          },
                          on: { click: _vm.onLikeButtonClick }
                        },
                        [_vm._v(_vm._s(_vm.likeLabel))]
                      )
                    : _vm._e(),
                  !_vm.search && _vm.reactionAvailable
                    ? _c(
                        "div",
                        {
                          class: _vm.likeCount ? "fav-link active" : "fav-link",
                          on: { click: _vm.showFavoriteList }
                        },
                        [_vm._v(_vm._s(_vm.numberOfLike))]
                      )
                    : _vm._e()
                ]),
                _vm.photos.length
                  ? _c("div", { staticClass: "photos" }, [
                      _c(
                        "div",
                        {
                          class:
                            _vm.photos.length == 1
                              ? "full-size-photo"
                              : "first-photo",
                          on: { click: _vm.openPhotoStream }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "mx-auto attachment-img-container" },
                            [
                              _c("attachment-img", {
                                attrs: {
                                  image: _vm.photos[0],
                                  param: {
                                    messageId: _vm.messageId,
                                    commentId: _vm.id
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _vm.photos.length > 1
                        ? _c(
                            "div",
                            {
                              staticClass: "other-photos ml-auto",
                              on: { click: _vm.openPhotoStream }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mx-auto attachment-img-container"
                                },
                                [
                                  _vm.photos.length == 2
                                    ? _c("attachment-img", {
                                        attrs: {
                                          image: _vm.photos[1],
                                          param: {
                                            messageId: _vm.messageId,
                                            commentId: _vm.id
                                          }
                                        }
                                      })
                                    : _c(
                                        "b-overlay",
                                        {
                                          staticClass:
                                            "d-flex justify-content-center",
                                          attrs: {
                                            show: "",
                                            "no-center": "",
                                            variant: "dark"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "overlay",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "p",
                                                      { staticClass: "cover" },
                                                      [
                                                        _vm._v(
                                                          "全" +
                                                            _vm._s(
                                                              _vm.photos.length
                                                            ) +
                                                            "枚"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            false,
                                            758820505
                                          )
                                        },
                                        [
                                          _c("attachment-img", {
                                            attrs: {
                                              image: _vm.photos[1],
                                              param: {
                                                messageId: _vm.messageId,
                                                commentId: _vm.id
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e()
                    ])
                  : _vm._e()
              ])
            ]
          ),
          _c("b-row", { staticClass: "comment-attachement" })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }