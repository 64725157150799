var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-modal", {
    attrs: {
      id: "sort-comment-setting",
      size: "lg",
      "hide-header": "",
      centered: "",
      "no-close-on-backdrop": ""
    },
    on: { show: _vm.initSortValue },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function() {
          return [
            _c(
              "div",
              {
                class: _vm.isMobile ? "mobile-mode" : "pc-mode",
                attrs: { id: "sort-comment-setting-group" }
              },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      "label-size": "lg",
                      "label-class": "font-weight-bold"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              { staticClass: "d-flex flex-directions-row" },
                              [
                                _c("div", [
                                  _vm._v(_vm._s(_vm.SORT_COMMENT_SETTING_TITLE))
                                ])
                              ]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  },
                  [
                    _c("div", { staticClass: "setting-description" }, [
                      _vm._v(" " + _vm._s(_vm.SORT_COMMENT_DESCRIPTION) + " ")
                    ]),
                    _c(
                      "b-form-radio-group",
                      {
                        attrs: { stacked: "" },
                        model: {
                          value: _vm.sortValue,
                          callback: function($$v) {
                            _vm.sortValue = $$v
                          },
                          expression: "sortValue"
                        }
                      },
                      [
                        _c("b-form-radio", { attrs: { value: "ASC" } }, [
                          _vm._v(_vm._s(_vm.ASC_LABEL))
                        ]),
                        _c("b-form-radio", { attrs: { value: "DESC" } }, [
                          _vm._v(_vm._s(_vm.DESC_LABEL))
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "modal-footer",
        fn: function(ref) {
          var close = ref.close
          return [
            _c(
              "b-row",
              {
                staticClass: "w-100",
                attrs: { "align-h": "around", "no-gutters": "" }
              },
              [
                _c("cancel", {
                  staticClass: "col-5",
                  attrs: { label: "キャンセル" },
                  on: { cancel: close }
                }),
                _c("confirm", {
                  staticClass: "col-5",
                  attrs: { label: "保存", disabled: _vm.disabled },
                  on: {
                    confirm: function($event) {
                      return _vm.saveSortValue(close)
                    }
                  }
                })
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }