











































































































































































































































import { User, Category } from "../model";
import CategoryEdit from "@/components/CategoryEdit.vue";
import Cancel from "@/components/button/Cancel.vue";
import Confirm from "@/components/button/Confirm.vue";
import RestrictTypeSetting from "@/components/RestrictTypeSetting.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { RestrictType, CustomFileType, CustomDeviceType } from "@/domain-setting-types";
import { SetDomainPolicyPayload } from "@/store/domain-store";
import { AuthorityGroupPolicy, CreateTopicAuthorityGroupPolicyDefault, UpdateTopicAuthorityGroupPolicyDefault } from "@/support-acl-types";
import { AttachmentPolicy } from "@/API";
import { AttachmentFileTypes, AttachmentFileTypesDefault, DownloadDeviceTypes, DownloadDeviceTypesDefault, DownloadPolicyDefault } from "@/suppport-attachment-types";
import { allowFeature, FeatureName } from "@/direct-app-config";

@Component({
    components: {
        CategoryEdit,
        RestrictTypeSetting,
        Cancel,
        Confirm
    }
})
export default class DomainSetting extends Vue {
    name: string = "domain-setting";

    DEFAULT_DESCRIPTION: string = "設定の変更は、所有者へご依頼ください。";
    ATTACH_DESC: string = "「directの設定に準ずる」を選択している際にdirect側の設定を変更した場合、設定を反映するため掲示板のリロードを実行してください。ブラウザの場合はブラウザの更新、アプリの場合はトークに一度戻る操作を行ってください。";
    DOWNLOAD_DESC: string = "「directの設定に準ずる」を選択している際にdirect側の設定を変更した場合、設定を反映するため掲示板のリロードを実行してください。ブラウザの場合はブラウザの更新、アプリの場合はトークに一度戻る操作を行ってください。こちらの設定の「許可」で「iOSアプリ」「Androidアプリ」を選択していても、direct側の設定がダウンロード無効の場合は、iOSアプリ・Androidアプリではダウンロードは行えません。";

    // 話題の作成権限
    CREATE_TOPIC_TITLE: string = "話題の作成権限";
    CREATE_TOPIC_DESC: string = "所有者は常に作成可、ゲストは常に作成不可です。";
    CREATE_TOPIC_ADMIN_LABEL: string = "管理者権限でも作成可とする";
    allowCreateTopicByAdmin: boolean = true; // [管理者]話題作成 default: 許可
    CREATE_TOPIC_USER_LABEL: string = "ユーザー権限でも作成可とする";
    allowCreateTopicByUser: boolean = true; // [ユーザー]話題作成 default: 許可

    // 話題の更新権限
    UPDATE_TOPIC_TITLE: string = "話題の編集権限";
    UPDATE_TOPIC_DESC: string = "所有者は常に編集可、ユーザー・ゲストは常に編集不可です。\n※話題の作成者は、権限に限らず自分が作成した話題は、常に編集可です。\n※編集には削除も含みます。";
    UPDATE_TOPIC_ADMIN_LABEL: string = "管理者権限でも編集可とする";
    allowUpdateTopicByAdmin: boolean = false; // [管理者]話題更新 default: 禁止

    // 添付ファイルダウンロード設定の許可オプション
    DONWLOAD_ALLOW_OPTIONS = [
        { text: "Web版", value: "web" },
        { text: "iOSアプリ", value: "ios" },
        { text: "Androidアプリ", value: "android" },
        { text: "デスクトップアプリ", value: "desktop" },
    ];
    DOWNLOAD_CUSTOM_OPTION_ALERT: string = "ダウンロードを許可する環境を選択してください";

    // storeから取得?
    allowCatEditExceptGuest: boolean = true;
    allowTopicWriteOthers: boolean = true;
    allowMessageWriteOthers: boolean = true;

    attachRestrictType: RestrictType = RestrictType.permit;
    attachCustomFileType: CustomFileType[] = [];
    downloadRestrictType: RestrictType = RestrictType.omit;
    downloadCustomFileType: CustomDeviceType[] = [];

    // category-edit
    @Prop({ default: () => [] }) readonly categories!: Category[];
    @Prop({ default: "" }) readonly domainId!: string;
    @Prop({ default: () => User.createNotFoundUser() }) readonly me!: User;
    // for storybook
    @Prop({ default: false }) show!: boolean;
    @Prop({ default: CreateTopicAuthorityGroupPolicyDefault }) readonly initalCreateTopicAuthorityGroupPolicy!: AuthorityGroupPolicy;
    @Watch('initalCreateTopicAuthorityGroupPolicy', { immediate: true }) onCreateTopicAuthorityGroupPolicy() {
        if( this.initalCreateTopicAuthorityGroupPolicy.admin !== undefined ) {
            this.allowCreateTopicByAdmin = this.initalCreateTopicAuthorityGroupPolicy.admin;
        }
        if( this.initalCreateTopicAuthorityGroupPolicy.user !== undefined ) {
            this.allowCreateTopicByUser = this.initalCreateTopicAuthorityGroupPolicy.user;
        }
    }
    @Prop({ default: UpdateTopicAuthorityGroupPolicyDefault }) readonly initalUpdateTopicAuthorityGroupPolicy!: AuthorityGroupPolicy;
    @Watch('initalUpdateTopicAuthorityGroupPolicy', { immediate: true }) onUpdateTopicAuthorityGroupPolicy() {
        if( this.initalUpdateTopicAuthorityGroupPolicy.admin !== undefined ) {
            this.allowUpdateTopicByAdmin = this.initalUpdateTopicAuthorityGroupPolicy.admin;
        }
    }
    @Prop({ default: RestrictType.permit }) initalAttachRestrictType!: RestrictType;
    @Watch('initalAttachRestrictType', { immediate: true }) onAttachRestrictType() {
        this.attachRestrictType = this.initalAttachRestrictType;
    }
    @Prop({ default: () => [] }) initalAttachCustomFileType!: CustomFileType[];
    @Watch('initalAttachCustomFileType', { immediate: true }) onAttachCustomFileType() {
        this.attachCustomFileType = this.initalAttachCustomFileType;
    }
    @Prop({ default: RestrictType.omit }) initalDownloadRestrictType!: RestrictType;
    @Watch('initalDownloadRestrictType', { immediate: true }) onDownloadRestrictType() {
        this.downloadRestrictType = this.initalDownloadRestrictType;
    }
    @Prop({ default: () => [] }) initalDownloadCustomFileType!: CustomDeviceType[];
    @Watch('initalDownloadCustomFileType', { immediate: true }) onDownloadCustomFileType() {
        this.downloadCustomFileType = this.initalDownloadCustomFileType;
    }

    // 編集権の設定 > 管理者以上
    get showEditSetting(): boolean {
        return allowFeature( "domain-setting-category", this.$store )
            && (
                this.me.isOwnerRole(this.domainId)
                || this.me.isAdminRole(this.domainId)
                    || this.me.isUserRole(this.domainId)
            )
    }
    // 組織設定 > 管理者以上
    get showAclSetting(): boolean {
        return allowFeature( "domain-settings-acl", this.$store )
                && (
                    this.me.isOwnerRole(this.domainId)
                || this.me.isAdminRole(this.domainId)
                )
    }
    // 話題作成権限設定 > (表示は)管理者以上
    get showCreateTopicAclSetting(): boolean {
        return this.me.isOwnerRole(this.domainId)
                || this.me.isAdminRole(this.domainId)
    }
    // 話題更新権限設定 > (表示は)管理者以上
    get showUpdateTopicAclSetting(): boolean {
        return this.me.isOwnerRole(this.domainId)
                || this.me.isAdminRole(this.domainId)
    }
    // 添付ファイル設定 > (表示は)管理者以上
    get showAttachSetting(): boolean {
        return this.me.isOwnerRole(this.domainId)
                || this.me.isAdminRole(this.domainId)
    }
    // 添付ファイルダウンロード設定 > (表示は)管理者以上
    get showDownloadSetting(): boolean {
        return this.me.isOwnerRole(this.domainId)
                || this.me.isAdminRole(this.domainId)
    }
    // 設定を保存できない(保存ボタンをdisabledにする)条件
    get invalidSetting(): boolean {
        // 添付の項目で、「許可」かつ「詳細設定のチェックが0」
        // もしくは、保存の項目で「許可」かつ「詳細設定のチェックが0」
        return this.attachRestrictType === 1
                && !this.attachCustomFileType.length
                    || this.downloadRestrictType === 1
                        && !this.downloadCustomFileType.length;
    }

    mounted(): void {
        // for storybook
        if (this.show) {
            this.$bvModal.show("domain-setting");
        }
    }
    changeAttchRestrictType(prop: {
        restrictType: RestrictType;
        customFileType: CustomFileType[];
    }) {
        this.attachRestrictType = prop.restrictType;
        this.attachCustomFileType = prop.customFileType;
    }

    changeDownloadRestrictType(prop: {
        restrictType: RestrictType;
        customFileType: CustomDeviceType[];
    }) {
        this.downloadRestrictType = prop.restrictType;
        this.downloadCustomFileType = prop.customFileType;
    }

    async showSaveAlert(): Promise<void> {

        // 添付ファイル、ダウンロード設定
            const policy: SetDomainPolicyPayload = {
                domainId: this.domainId,
                createTopicAuthorityGroupPolicy: CreateTopicAuthorityGroupPolicyDefault,
                updateTopicAuthorityGroupPolicy: UpdateTopicAuthorityGroupPolicyDefault,
                attachmentPolicy: AttachmentPolicy.FOLLOW_DIRECT,
                attachmentFileType: AttachmentFileTypesDefault,
                downloadPolicy: DownloadPolicyDefault,
                downloadDeviceType: DownloadDeviceTypesDefault,
            }

            policy.createTopicAuthorityGroupPolicy = { admin: this.allowCreateTopicByAdmin, user: this.allowCreateTopicByUser };
            policy.updateTopicAuthorityGroupPolicy = { admin: this.allowUpdateTopicByAdmin };

            // 添付ファイル
            switch( this.attachRestrictType ) {
                case RestrictType.omit:     policy.attachmentPolicy = AttachmentPolicy.DENY; break;
                case RestrictType.permit:   policy.attachmentPolicy = AttachmentPolicy.ALLOW; break;
                default:                    policy.attachmentPolicy = AttachmentPolicy.FOLLOW_DIRECT; break;
            }
            const attachmentKeys = Object.keys( policy.attachmentFileType ) as ( keyof AttachmentFileTypes )[];
            attachmentKeys.forEach( ( key ) => {
                policy.attachmentFileType[ key ] = this.attachCustomFileType.findIndex( type => type == key ) != -1;
            })

            // ダウンロード
            switch( this.downloadRestrictType ) {
                case RestrictType.omit:     policy.downloadPolicy = AttachmentPolicy.DENY; break;
                case RestrictType.permit:   policy.downloadPolicy = AttachmentPolicy.ALLOW; break;
                default:                    policy.downloadPolicy = AttachmentPolicy.FOLLOW_DIRECT; break;
            }
            const downloadKeys = Object.keys( policy.downloadDeviceType ) as ( keyof DownloadDeviceTypes )[];
            downloadKeys.forEach( ( key ) => {
                policy.downloadDeviceType[ key ] = this.downloadCustomFileType.findIndex( type => type == key ) != -1;
            })
            await this.$store.dispatch( "domains/setDomainPolicy", policy )

        // ダイアログ閉じる
        this.$bvModal.hide("domain-setting");
    }
}
