







import { IndexedDB } from '@/indexeddb';
import { Component, Vue } from 'vue-property-decorator';

import AppAuth, { PRODUCT_STAGE, PROVIDER } from "../aws-config";
import { ServerApiAccess } from '@/server-api-access';
import { isMobile, osType } from '@/mobile';

@Component({
  components: {
  },
})
export default class Logout extends Vue {
    async created(): Promise<void> {
        let redirectUrl = undefined;
        // direct トークン取り消し処理
        const revokeTokenPromise = async () => {
            const api = new ServerApiAccess();
            await api.revokeToken();
        } 
        // DB削除
        const dropDBPromise = async () => {
            await IndexedDB.dropDB();
        }
        // サインアウト
        const signOutPromise = async () => {
            const url = await AppAuth.signOut();
            if( url ) { redirectUrl = url; }
        }
        // 成功失敗に関わらず全て実行
        await Promise.allSettled([revokeTokenPromise(), dropDBPromise(), signOutPromise() ]);
        if( redirectUrl ) {
            let provider: PROVIDER = "direct";
            switch( PRODUCT_STAGE ) {
                case "PRD": provider = "direct"; break;
                case "STG": provider = "direct-staging"; break;
                case "DEV": provider = "direct-dev"; break;
                default:    provider = "direct"; break;
            }
            const device = this.$store.getters["device"];
            if( isMobile( device ) ) { // スマホは別処理
                const type = osType( device );
                switch( type ) {
                    case "ios":     await AppAuth.redirectOidcLoginUrl( "direct-mobile", "ios" ); break;
                    case "android": await AppAuth.redirectOidcLoginUrl( "direct-mobile", "and" ); break;
                    default:        await AppAuth.redirectOidcLoginUrl( provider ); break;
                }
            } else {
                await AppAuth.redirectOidcLoginUrl(provider);
            }
        } else {
            this.$router.replace("/", () => {
                // "/" → "/" での NavigationDuplicated が出る場合があるが、無視する
            });
        }
    }
}
