var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar-nav",
    { staticClass: "nav-right" },
    [
      _vm.ok
        ? _c(
            "b-navbar-nav",
            [
              _c(
                "b-nav-item",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("on-ok")
                    }
                  }
                },
                [_vm._v("OK")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.complete
        ? _c(
            "b-navbar-nav",
            [
              _c(
                "b-nav-item",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("on-complete")
                    }
                  }
                },
                [_vm._v("完了")]
              )
            ],
            1
          )
        : _vm._e(),
      0 <= _vm.items.length
        ? _c(
            "b-nav-form",
            _vm._l(_vm.items, function(item) {
              return _c(
                "b-nav-item",
                {
                  key: item.label,
                  attrs: { disabled: item.disabled },
                  on: {
                    click: function($event) {
                      return item.click()
                    }
                  }
                },
                [
                  item.icon
                    ? _c("b-icon", { attrs: { icon: item.icon } })
                    : _c("span", [_vm._v(" " + _vm._s(item.label) + " ")])
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _vm.preset
        ? _c(
            "b-nav-form",
            { staticStyle: { "min-width": "max-content" } },
            [
              _c(
                "b-nav-item",
                { staticClass: "nav-solution-links" },
                [
                  _c("nav-bar-solution-button", {
                    attrs: {
                      "solution-links": _vm.solutionLinks,
                      "domain-id": _vm.domainId
                    }
                  })
                ],
                1
              ),
              _c(
                "b-nav-item-dropdown",
                {
                  staticClass: "profile-menu",
                  attrs: {
                    id: "menu-button",
                    "menu-class": "profile-menu-container",
                    right: "",
                    "no-caret": ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "button-content",
                        fn: function() {
                          return [
                            _c("profile-icon", {
                              staticClass: "profile-menu",
                              attrs: {
                                "user-name": _vm.userName,
                                id: _vm.userId,
                                image: _vm.userImage,
                                size: 36,
                                "use-hover-style": true
                              }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2106083655
                  )
                },
                [
                  _c("b-dropdown-item", {
                    staticClass: "usermenu-item-profile usermenu-item-disable",
                    attrs: { disabled: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                { staticClass: "usermenu-item-username" },
                                [_vm._v(_vm._s(_vm.userName))]
                              ),
                              _c(
                                "div",
                                { staticClass: "usermenu-item-email" },
                                [_vm._v(_vm._s(_vm.userEmail))]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      78812931
                    )
                  }),
                  _c("b-dropdown-divider"),
                  _vm.isEditDomainSetting && _vm.allowFeature("domain-settings")
                    ? _c(
                        "b-dropdown-group",
                        { attrs: { header: "App管理" } },
                        [
                          _c(
                            "b-dropdown-item",
                            { on: { click: _vm.showDomainSetting } },
                            [_vm._v("掲示板Appの設定")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isEditDomainSetting && _vm.allowFeature("domain-settings")
                    ? _c("b-dropdown-divider")
                    : _vm._e(),
                  _c(
                    "b-dropdown-group",
                    { attrs: { header: "個人設定" } },
                    [
                      _c(
                        "b-dropdown-item",
                        { on: { click: _vm.showSortCommentSetting } },
                        [_vm._v("コメント並び順設定")]
                      )
                    ],
                    1
                  ),
                  _c("b-dropdown-divider"),
                  _vm.hasFeature("usermenu-help")
                    ? _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            href: "https://help.direct4b.app/",
                            target: "_blank",
                            rel: "noopener noreferrer"
                          }
                        },
                        [_vm._v("ヘルプ")]
                      )
                    : _vm._e(),
                  _c("b-dropdown-item", { on: { click: _vm.syncLoad } }, [
                    _vm._v("同期")
                  ]),
                  _c(
                    "b-dropdown-item",
                    { attrs: { id: "menu-logout" }, on: { click: _vm.logout } },
                    [_vm._v("ログアウト")]
                  ),
                  _vm.hasFeature("debug-sentry")
                    ? _c("b-dropdown-divider")
                    : _vm._e(),
                  _vm.hasFeature("debug-sentry")
                    ? _c(
                        "b-dropdown-item",
                        { on: { click: _vm.onClickDebugSentry } },
                        [_vm._v("Sentryエラーテスト")]
                      )
                    : _vm._e(),
                  _vm.hasFeature("debug-client-type") &&
                  _vm.env !== "production"
                    ? _c(
                        "b-dropdown-item",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal",
                              value: "debug-client-type",
                              expression: "'debug-client-type'"
                            }
                          ]
                        },
                        [_vm._v("クライアントタイプ変更")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.close
        ? _c(
            "b-nav-form",
            [
              _c(
                "b-nav-item",
                {
                  staticStyle: {
                    "font-size": "x-large",
                    "padding-right": "0px"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("on-close")
                    }
                  }
                },
                [_c("b-icon", { attrs: { icon: "x" } })],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.appClose
        ? _c(
            "b-nav-form",
            [
              _vm.env !== "production"
                ? _c(
                    "b-dropdown",
                    {
                      attrs: {
                        text: "開発用",
                        variant: "outline",
                        "toggle-class": "debug-btn",
                        right: true
                      }
                    },
                    [
                      _vm.hasFeature("debug-sentry")
                        ? _c(
                            "b-dropdown-item",
                            { on: { click: _vm.onClickDebugSentry } },
                            [_vm._v("Sentryエラーテスト")]
                          )
                        : _vm._e(),
                      _vm.hasFeature("debug-client-type") &&
                      _vm.env !== "production"
                        ? _c(
                            "b-dropdown-item",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal",
                                  value: "debug-client-type",
                                  expression: "'debug-client-type'"
                                }
                              ]
                            },
                            [_vm._v("クライアントタイプ変更")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-nav-item",
                {
                  staticStyle: { "padding-right": "0px" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("on-menu-open")
                    }
                  }
                },
                [_c("b-icon", { attrs: { icon: "three-dots" } })],
                1
              ),
              _c(
                "b-nav-item",
                {
                  staticStyle: {
                    "font-size": "x-large",
                    "padding-right": "0px"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("on-app-close")
                    }
                  }
                },
                [_c("b-icon", { attrs: { icon: "x" } })],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.empty
        ? _c(
            "b-nav-form",
            [
              _c(
                "b-nav-item",
                { staticClass: "invisible", attrs: { disabled: "" } },
                [_c("b-icon", { attrs: { icon: "x" } })],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.hasFeature("debug-client-type") && _vm.env !== "production"
        ? _c(
            "b-modal",
            {
              attrs: { id: "debug-client-type" },
              on: {
                show: function($event) {
                  _vm.clientTypeCheckValue = _vm.clientType
                },
                ok: _vm.debugChangeClientType
              }
            },
            [
              _c(
                "b-form-group",
                [
                  _c(
                    "b-form-radio",
                    {
                      attrs: { value: "web" },
                      model: {
                        value: _vm.clientTypeCheckValue,
                        callback: function($$v) {
                          _vm.clientTypeCheckValue = $$v
                        },
                        expression: "clientTypeCheckValue"
                      }
                    },
                    [_vm._v("web")]
                  ),
                  _c(
                    "b-form-radio",
                    {
                      attrs: { value: "ios" },
                      model: {
                        value: _vm.clientTypeCheckValue,
                        callback: function($$v) {
                          _vm.clientTypeCheckValue = $$v
                        },
                        expression: "clientTypeCheckValue"
                      }
                    },
                    [_vm._v("ios")]
                  ),
                  _c(
                    "b-form-radio",
                    {
                      attrs: { value: "android" },
                      model: {
                        value: _vm.clientTypeCheckValue,
                        callback: function($$v) {
                          _vm.clientTypeCheckValue = $$v
                        },
                        expression: "clientTypeCheckValue"
                      }
                    },
                    [_vm._v("android")]
                  ),
                  _c(
                    "b-form-radio",
                    {
                      attrs: { value: "desktop" },
                      model: {
                        value: _vm.clientTypeCheckValue,
                        callback: function($$v) {
                          _vm.clientTypeCheckValue = $$v
                        },
                        expression: "clientTypeCheckValue"
                      }
                    },
                    [_vm._v("desktop")]
                  ),
                  _c(
                    "b-form-radio",
                    {
                      attrs: { value: "unknown" },
                      model: {
                        value: _vm.clientTypeCheckValue,
                        callback: function($$v) {
                          _vm.clientTypeCheckValue = $$v
                        },
                        expression: "clientTypeCheckValue"
                      }
                    },
                    [_vm._v("unknown")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }