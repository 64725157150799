var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { class: _vm.classes, attrs: { id: "msg_" + _vm.id, fluid: "" } },
    [
      _c(
        "b-row",
        { staticClass: "px-3 py-2" },
        [
          _c("div", {
            ref: "message-title",
            staticClass: "message-title",
            domProps: {
              innerHTML: _vm._s(
                _vm.$sanitize(_vm.titleLabel, {
                  allowedAttributes: { span: ["class"] }
                })
              )
            }
          }),
          _c("b-tooltip", {
            attrs: {
              target: function() {
                return _vm.$refs["message-title"]
              },
              title: _vm.titleLabel
            },
            on: { show: _vm.onTitleTooltipShow }
          }),
          _c("div", { staticClass: "d-flex  ml-auto" }, [
            _c(
              "div",
              [
                !_vm.search
                  ? _c(
                      "b-iconstack",
                      {
                        staticClass: "three-dots",
                        attrs: {
                          id: "edit-button-" + _vm.id,
                          "font-scale": "1",
                          tabindex: "0"
                        }
                      },
                      [_c("b-icon-three-dots")],
                      1
                    )
                  : _vm._e(),
                _c(
                  "b-popover",
                  {
                    ref: "popover",
                    attrs: {
                      target: "edit-button-" + _vm.id,
                      placement: "bottom",
                      triggers: "focus"
                    }
                  },
                  [
                    _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "edit-menu-item",
                          on: { click: _vm.onUrlCopyMessage }
                        },
                        [_vm._v("URLコピー")]
                      ),
                      _vm.reactionAvailable
                        ? _c(
                            "div",
                            {
                              staticClass: "edit-menu-item",
                              on: { click: _vm.showFavoriteList }
                            },
                            [_vm._v("いいね！の一覧を表示する")]
                          )
                        : _vm._e(),
                      _vm.editable
                        ? _c(
                            "div",
                            {
                              staticClass: "edit-menu-item",
                              on: { click: _vm.onEditMessage }
                            },
                            [_vm._v("編集")]
                          )
                        : _vm._e(),
                      _vm.deletable
                        ? _c(
                            "div",
                            {
                              staticClass: "edit-menu-item",
                              on: { click: _vm.onDeleteMessage }
                            },
                            [_vm._v("削除")]
                          )
                        : _vm._e()
                    ])
                  ]
                )
              ],
              1
            ),
            _vm.pinned
              ? _c(
                  "div",
                  { on: { click: _vm.onStarClick } },
                  [_c("icon-pinned", { staticClass: "star" })],
                  1
                )
              : _vm.starFlag && !_vm.search
              ? _c(
                  "div",
                  { on: { click: _vm.onStarClick } },
                  [
                    _c(
                      "b-iconstack",
                      { staticClass: "star", attrs: { "font-scale": "1" } },
                      [_c("b-icon-star-fill", { staticClass: "has-star" })],
                      1
                    )
                  ],
                  1
                )
              : !_vm.search
              ? _c(
                  "div",
                  { on: { click: _vm.onStarClick } },
                  [
                    _c(
                      "b-iconstack",
                      { staticClass: "star", attrs: { "font-scale": "1" } },
                      [_c("b-icon-star-fill", { staticClass: "not-star" })],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "px-3" },
        [
          _c(
            "div",
            { staticClass: "p-auto" },
            [
              _c("profile-icon", {
                staticClass: "topic-list-item-icon rounded-circle m-auto",
                attrs: {
                  userName: _vm.contributor.name,
                  id: _vm.contributorId,
                  image: _vm.contributorIcon,
                  size: "25"
                }
              })
            ],
            1
          ),
          _c("div", {
            ref: "contributor",
            staticClass: "contributor",
            domProps: {
              innerHTML: _vm._s(
                _vm.$sanitize(_vm.contributor.name, {
                  allowedAttributes: { span: ["class"] }
                })
              )
            }
          }),
          _c("b-tooltip", {
            attrs: {
              target: function() {
                return _vm.$refs["contributor"]
              },
              title: _vm.contributor.name
            },
            on: { show: _vm.onUserTooltipShow }
          }),
          _c("div", { staticClass: "time-stamp" }, [
            _vm._v(_vm._s(_vm.timeStamp))
          ])
        ],
        1
      ),
      _c("b-row", { staticClass: "px-3 pt-2" }, [
        _c("div", { staticClass: "content" }, [
          _c("div", {
            staticClass: "mb-2",
            domProps: {
              innerHTML: _vm._s(
                _vm.$sanitize(_vm.contents, {
                  allowedAttributes: {
                    span: ["class"],
                    a: ["href", "name", "target", "rel"]
                  }
                })
              )
            }
          }),
          _vm.photos.length
            ? _c(
                "div",
                {
                  staticClass: "photos d-flex",
                  staticStyle: { "flex-direction": "rows" }
                },
                [
                  _c(
                    "div",
                    {
                      class:
                        _vm.photos.length == 1
                          ? "full-size-photo"
                          : "first-photo",
                      on: { click: _vm.openPhotoStream }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mx-auto attachment-img-container" },
                        [
                          _c("attachment-img", {
                            attrs: {
                              image: _vm.photos[0],
                              param: { messageId: _vm.id, commentId: "" }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm.photos.length > 1
                    ? _c(
                        "div",
                        {
                          staticClass: "other-photos ml-auto",
                          on: { click: _vm.openPhotoStream }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "mx-auto attachment-img-container" },
                            [
                              _vm.photos.length == 2
                                ? _c("attachment-img", {
                                    attrs: {
                                      image: _vm.photos[1],
                                      param: {
                                        messageId: _vm.id,
                                        commentId: ""
                                      }
                                    }
                                  })
                                : _c(
                                    "b-overlay",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center",
                                      attrs: { show: "", variant: "dark" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "overlay",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "p",
                                                  { staticClass: "cover" },
                                                  [
                                                    _vm._v(
                                                      "全" +
                                                        _vm._s(
                                                          _vm.photos.length
                                                        ) +
                                                        "枚"
                                                    )
                                                  ]
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        false,
                                        758820505
                                      )
                                    },
                                    [
                                      _c("attachment-img", {
                                        attrs: {
                                          image: _vm.photos[1],
                                          param: {
                                            messageId: _vm.id,
                                            commentId: ""
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e()
                ]
              )
            : _vm._e()
        ])
      ]),
      !_vm.search
        ? _c("b-row", { staticClass: "px-3" }, [
            _c("div", { staticClass: "text-devider" })
          ])
        : _vm._e(),
      !_vm.search
        ? _c(
            "b-row",
            {
              staticClass: "d-flex px-3 py-2 justify-content-end",
              staticStyle: { "align-items": "center" }
            },
            [
              _vm.reactionAvailable
                ? _c("div", { staticClass: "d-inline-flex" }, [
                    _c("div", { staticClass: "like-stamp" }, [
                      _vm.likeFlag
                        ? _c(
                            "div",
                            {
                              staticClass: "liked",
                              on: { click: _vm.onLikeClick }
                            },
                            [
                              _c("icon-like", { staticClass: "liked-icon" }),
                              _vm._v("いいね！")
                            ],
                            1
                          )
                        : _c(
                            "div",
                            {
                              staticClass: "normal",
                              on: { click: _vm.onLikeClick }
                            },
                            [
                              _c("icon-like", { staticClass: "normal-icon" }),
                              _vm._v("いいね！")
                            ],
                            1
                          )
                    ]),
                    _c(
                      "div",
                      {
                        class: _vm.likeCount ? "fav-link active" : "fav-link",
                        on: { click: _vm.showFavoriteList }
                      },
                      [_vm._v(_vm._s(_vm.likeStamp))]
                    ),
                    _c("div", { staticClass: "fav-link mx-1" }, [_vm._v("|")])
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "comment-link", on: { click: _vm.clickedLink } },
                [_vm._v(_vm._s(_vm.commentLink))]
              ),
              _c(
                "b-form-group",
                { staticClass: "my-auto" },
                [
                  _c("b-form-radio-group", {
                    attrs: {
                      options: [
                        { text: "昇順", value: "ASC" },
                        { text: "降順", value: "DESC" }
                      ],
                      "button-variant": "outline-info",
                      size: "sm",
                      buttons: ""
                    },
                    model: {
                      value: _vm.sortValue,
                      callback: function($$v) {
                        _vm.sortValue = $$v
                      },
                      expression: "sortValue"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        [
          _vm.showCommentList
            ? _c("comment-list", {
                attrs: {
                  id: _vm.id,
                  comments: _vm.commentSource,
                  users: _vm.users,
                  viewer: _vm.viewer,
                  allow_attachment_type: _vm.allow_attachment_type,
                  sort: _vm.sortValue
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }