











































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class MessagePageHeader extends Vue {
    name: string = 'message-page-header';

    @Prop({ required: true }) readonly titleLabel!: string;
    @Prop({ required: true }) readonly domainId!: string; //!< 所属組織ID
    @Prop({ required: true }) readonly topicId!: string;  //!< 所属話題ID

    get isMobile(): boolean {
        return this.$store.getters["isMobile"];
    }

    async back(): Promise<void> {
        if( this.$router ) {
            await this.$store.dispatch('messages/reset', {domainId: this.domainId, topicId: this.topicId, messageId: this.$route.params.messageId});
            this.$store.dispatch('setDomainId', this.domainId);
            this.$store.dispatch('setTopicId', ''); // topicIdのリセット
            this.$router.push({ path: `/${this.domainId}` })
        }
    }

    // タイトル省略時のツールチップを出すかどうかを判定する
    onTooltipShow( bvEvent: Event, id: string ): void {
        const target = this.$refs[ id ];
        if( target instanceof HTMLElement ) {
            // webkit-line-clamp で省略された時、offsetHeight < scrollHeigt になる
            const oHeight = target.offsetHeight;
            const sHeight = target.scrollHeight;
            // text-overflow で省略されたとき、offsetWidth < scrollWidth になる
            const oWidth = target.offsetWidth;
            const sWidth = target.scrollWidth;
            if( oHeight < sHeight || oWidth < sWidth ) return;
        }
        bvEvent.preventDefault(); // 出さない
    }
}
