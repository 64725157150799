






































































































































































import { mixins } from "vue-class-component";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import MessageListItem from './MessageListItem.vue';
import './message-list.scss';
import MessageEditModal from './MessageEditModal.vue';
import IconPinned from './icon/IconPinned.vue';
import ProfileIcon from "./ProfileIcon.vue";
import Fab from './Fab.vue';
import { Acl, Attachment, Category, Message, User, } from '../model';
import TextProcessMixin from "./mixin/TextProcessMixin";
import ClipboardCopyMixin from "./mixin/ClipboardCopyMixin";
import type { IconReaction } from "../API"
import { IconReactionType } from "../API";
import { COLOR_PALETTE, DEFAULT_COLOR } from "./color-palette";
import store, { SORT_TYPE } from "../store";
import { Route, NavigationGuardNext } from "vue-router";
import DeleteModal from "./DeleteModal.vue";
import type { PayloadRegisterSubscription } from "../store/message-store";
import { ReactionController } from '../model/reaction-controller';
import { ActionPayload } from 'vuex';
import { BPopover, BvModalEvent } from 'bootstrap-vue';
import CommentEditModal from './CommentEditModal.vue';
import { PhotoStreamArgs } from './PhotoStream.vue';
import TopicEdit from './TopicEdit.vue';
import EditDiscardMixin from "./mixin/EditDiscardMixin";
import MessagePageHeader from "./MessagePageHeader.vue";

const escape = require('escape-html');
import sanitizeHTML from '../sanitize';
import { allowCreateMessage, allowFeature, FeatureName } from "../direct-app-config";
import AclManager from "../model/acl-manager";
Vue.prototype.$sanitize = sanitizeHTML;

import InfiniteLoading from 'vue-infinite-loading'
import { AttachmentFileTypes, AttachmentFileTypesDefault, AttachmentFileTypesNone } from "@/suppport-attachment-types";
import { LoadingManager } from "./loading/loading-manager";
Vue.use(InfiniteLoading)

const INITIAL_DISPLAY_NUMBER = 20;

type TopicEditParam = {
    type: string,
    categories: Category[],
    topicId: string,
    domainId: string,
    title: string,
    desc: string,
    me: User,
    thumb?: Attachment,
    category?: Category,
    pinned: boolean,
    allow_attachment_type: AttachmentFileTypes,
    acl: Acl,
}

type CommentEditModalParam = {
    commentId: string,
    messageId: string,
    message: string,
    photos: string[],
    allow_attachment_type: AttachmentFileTypes,
}

Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteUpdate',
    'beforeRouteLeave',
])
@Component({
    mixins: [
        ClipboardCopyMixin, TextProcessMixin, EditDiscardMixin,
    ],
    components: {
        MessageListItem, IconPinned, MessageEditModal, Fab, DeleteModal,
        ProfileIcon, CommentEditModal, TopicEdit, MessagePageHeader,
    }
})
export default class MessageList extends mixins( Vue, ClipboardCopyMixin, TextProcessMixin, EditDiscardMixin ){
    name: string = 'message-list';
    isMobile: boolean = false;

    // 投稿編集画面への引数
    type: string = 'create';
    modalId: string = '';
    modalTitle: string = '';
    modalMessage: string = '';
    modalPhotos: Attachment[] = [];
    modalAcl: Acl = Acl.createDummy();

    // コメント編集画面への引数
    commentEditModalParam: CommentEditModalParam = {
        commentId: '',
        messageId: '',
        message: '',
        photos: [],
        allow_attachment_type: AttachmentFileTypesDefault,
    }

    // 投稿削除モーダルへの引数
    deleteId: string = ""; // delete対象の投稿ID

    shownTopicEditModal:   boolean = false; // 話題編集モーダルの表示フラグ
    shownMessageEditModal: boolean = false; // 投稿編集モーダルの表示フラグ
    shownCommentEditModal: boolean = false; // コメント編集モーダルの表示フラグ

    replaceParam: { domainId: string, topicId: string } = { domainId: '', topicId: '' }; // router.replace用パラメータ

    topicEditParam: TopicEditParam = {
        type: 'edit',
        categories: [],
        topicId: '',
        domainId: '',
        title: '',
        desc: '',
        me: User.createNotFoundUser(),
        thumb: undefined,
        category: undefined,
        pinned: false,
        allow_attachment_type: AttachmentFileTypesDefault,
        acl: Acl.createDummy(),
    }

    // Vuexの変更監視
    unsubscribeMutation?: () => void;
    unsubscribeAction?: () => void;

    hideListener?: () => void;

    @Prop({ required: true })   readonly domainId!: string; //!< 所属組織ID
    @Prop({ required: true })   readonly topicId!: string;  //!< 所属話題ID
    @Watch( "topicId", { immediate: true } ) onTopicIdChanged() {
        this.$nextTick( () => {
            if( this.$router ) {
                ( this as any ).$scrollTo( "#msglist", 0,{ container: "#msglist" } )
            }
        })
    }

    @Prop({ default: '' })
    readonly icon!: Attachment;

    @Prop({ default: 'カテゴリー', required: true })
    readonly category!: Category;

    @Prop({ default: 'タイトル', required: true })
    readonly title!: string;

    @Prop({ default: '説明', required: true })
    readonly desc!: string;

    // Topic関係
    @Prop({ default: false }) readonly pinned!: boolean;    //!< Topicの固定状態
    @Prop({ default: "" }) readonly topicOwner!: string;    //!< Topicのオーナー情報(CognitoUserId)
    @Prop({ default: () => Acl.createDummy() }) readonly topicAcl!: Acl;       //!< TopicのACL
    @Prop({ default: undefined }) readonly notification?: string;  //!< Topicの全体通知

    // 話題が削除されたか
    @Prop({ default: false }) readonly deleted!: boolean;

    // 投稿リスト
    @Prop({ default: () => [] }) readonly messages!: Message[];

    // ユーザー情報
    @Prop({ default: () => [] }) readonly users!: User[]; // 組織内ユーザー情報
    @Prop({ default: () => ( User.createNotFoundUser() ) }) readonly viewer!: User; // ログインユーザ情報

    // Reaction関係
    @Prop({ default: undefined }) readonly star?: IconReaction; // Topicの★

    // ファイル添付設定
    @Prop({ default: () => AttachmentFileTypesDefault }) readonly allow_attachment_type!: AttachmentFileTypes;

    // 添付可能か
    get allow_attachment(): boolean { return this.allow_attachment_type != AttachmentFileTypesNone; }

    // true: Fabボタンを表示する
    @Prop({ default: true }) readonly fab!: boolean;

    // 話題内の投稿を表示するか
    get isBlocked(): boolean {
        return this.deleted || ( !this.topicId && !!this.$route );
    }

    // 権限: 投稿作成OKかどうか
    get creatable(): boolean {
        if( !this.fab ) return false;   // fab出さない設定の場合はそちらに準じる
        if( this.hasFeature('deny-edit') == false ) return true; // 未対応組織
        return AclManager.getCreateMessageAcl( this.$store, this.domainId, this.topicId ) != "deny";
    }

    get noItemsError(): string {
        // viwerがゲスト + 話題がゲスト閲覧不可 の場合
        return this.viewer.isGuestRole( this.domainId ) && !this.topicAllowedGuest ? "ゲストの方はご覧いただけません" : "まだ投稿はありません";
    }

    get categoryLabel (): string {
        return this.category.title;
    }
    get categoryColor (): string {
        // カテゴリーなし
        if( !this.category ) return "";

        const color = this.category.color;
        const index = COLOR_PALETTE.findIndex( c => c == color );
        return index < 0 ? DEFAULT_COLOR : color;
    }
    get titleLabel (): string {
        return this.title
    }
    // Topic作成者情報
    get contributor (): User { return this.findUserForCognitoUserId( this.topicOwner ) }
    get contributorId(): string { return this.contributor.directId; }
    get contributorIcon(): string { return this.contributor.getProfileIconUrl(); }

    get descSource (): string {
        return this.highLightStr(this.desc, undefined);
    }

    // Topicの★のON/OFF、★数
    get topicStarFlag(): boolean { return this.star?.userIdList.includes( this.viewer.directId ) || false; }
    get topicStarCount(): number { return this.star?.userIdList.length || 0; }

    // 編集/投稿画面のパス(mobileではない場合modalのためpathなし)
    get editPath(): string {
        return this.isMobile ? `/${this.domainId}/${this.topicId}/post-message` : "";
    }

    // 編集/投稿モーダル(mobileの場合は画面遷移のためなし)
    get editModalId(): string {
        return this.isMobile ? "" : "modal-message";
    }

    get topicUpdatable(): boolean { return AclManager.updateTopic( this.$store, this.topic ) }
    get topicDeletable(): boolean { return AclManager.deleteTopic( this.$store, this.topic ) }
    get topicEditId(): string { return `edit-button-${this.topicId}` }
    get topicAllowedGuest(): boolean {
        if( !this.topicAcl ) return false;
        else return this.topicAcl.guest.read;
    }
    get topic() {
        return ( this.$store )
                ? this.$store.getters["topics/getOne"]( this.domainId, this.topicId )
                : undefined
    }

    infiniteId: number = 0;
    loading: boolean = false;
    listRange: { top: number, bot: number } = { top: 0, bot: INITIAL_DISPLAY_NUMBER };

    get messageList(): Message[] {
        if( this.scrollJumpFlag ) {
            return this.messages.filter( mes => mes.id === this.$route.params.messageId );
        }  else {
            return this.messages.slice( this.listRange.top, this.listRange.bot );
        }
    }

    get scrollJumpFlag(): boolean {
        if( this.$route ) {
            const msgId = this.$route.params.messageId
            const cmtId = this.$route.params.commentId
            if( msgId || cmtId ) return true;   // スクロールジャンプ有りにする
            else return false;
        } else {
            return false;
        }
    }

    // 個人設定のコメントソート順
    get baseSort (): SORT_TYPE {
        return this.$store.getters["getSortCommentSetting"](this.domainId) as SORT_TYPE || "ASC";
    }

    async infiniteHandler( $state: any ): Promise<void> {
        if( this.scrollJumpFlag || !this.$router ) {
            $state.complete();
            return;
        }
        if( this.loading ) return;
        this.loading = true;
        if( this.messages.length > this.messageList.length ) {
            // 描画されてないデータがある > 表示範囲拡大
            this.listRange.bot += INITIAL_DISPLAY_NUMBER;
            $state.loaded();
            this.$nextTick( () => {
                this.loading = false;
            })
        } else {
            // storeにあるデータは全て描画されている
            $state.complete();
            this.$nextTick( () => {
                this.loading = false;
            })
        }
    }

    userName( directId: string ): string {
        return this.findUser( directId ).name;
    }

    findUser( userDirectId: string ): User {
      const user = this.users.find( user => user.directId == userDirectId );
      return user ? user : User.createNotFoundUser( userDirectId );
    }

    findUserForCognitoUserId( cognitoUserId: string ): User {
        const user = this.users.find( user => user.id == cognitoUserId );
        return user ? user : User.createNotFoundUser( cognitoUserId );
    }

    created(): void {
        this.judgeMobile();
        window.addEventListener('resize', this.judgeMobile);
    }

    judgeMobile(): void {
        const mql = window.matchMedia('screen and (min-width: 768px)');
        this.isMobile = !mql.matches ? true : false;
    }


    openModal( value: string ): void {
        const message = this.messages.find( (m) => m.id === value );
        if(!message) return;
        const param: PhotoStreamArgs = { files: message.photos, messageId: message.id, commentId: '' };
        this.$root.$emit("open-photo-stream", param);
    }

    /** Topicの★を押した時の処理 */
    onTopicStarClick(): void {
        const ctl = new ReactionController( this.$store );
        const topic = ctl.findTopic( this.topicId );
        if( !topic ) return;
        ctl.onReactionClicked( topic, IconReactionType.FAVORITE, this.viewer, this.topicStarFlag );
    }

    // タイトル省略時のツールチップを出すかどうかを判定する
    onTooltipShow( bvEvent: Event, id: string ): void {
        const target = this.$refs[ id ];
        if( target instanceof HTMLElement ) {
            // webkit-line-clamp で省略された時、offsetHeight < scrollHeigt になる
            const oHeight = target.offsetHeight;
            const sHeight = target.scrollHeight;
            // text-overflow で省略されたとき、offsetWidth < scrollWidth になる
            const oWidth = target.offsetWidth;
            const sWidth = target.scrollWidth;
            if( oHeight < sHeight || oWidth < sWidth ) return;
        }
        bvEvent.preventDefault(); // 出さない
    }

    // Urlコピー
    onUrlCopyTopic(): void {
        ( this.$refs.popover as BPopover).$emit('close'); // Close
        this.onUrlCopy({ domainId: this.domainId, topicId: this.topicId, messageId: "", commentId: "" });
    }

    // Topicの編集メニュー
    onTopicEditMessage(): void {
        if( this.$store ) {
            const domainId = this.$store.getters["domainId"] as string;
            const topicId = this.topicId;
            if(this.isMobile) {
                this.$router.push( `/${domainId}/${topicId}/edit-topic` )
            } else {
                const categories = store.getters["categories/get"]( domainId ) as Category[];
                this.topicEditParam = {
                    type: 'edit',
                    categories: categories,
                    topicId: this.topicId,
                    domainId: this.domainId,
                    title: this.title,
                    desc: this.desc,
                    me: this.viewer,
                    thumb: this.icon,
                    category: this.category,
                    pinned: this.pinned,
                    allow_attachment_type: this.allow_attachment_type,
                    acl: this.topicAcl,
                }
                this.$bvModal.show("modal-topic");
            }
        }
    }
    // Topicの削除メニュー
    onTopicDeleteMessage(): void {
        this.$bvModal.show( 'delete-topic-modal' );
    }

    // Fabボタンをクリックされたときの処理
    fabClicked(): void {
        if( allowCreateMessage( this.domainId, this.topicId, this.$store ) == false ) {
            this.$root.$emit('free-alert');
            return;
        }

        // モバイル版の場合は新規作成画面を開く
        // その他の場合はモーダル
        if( this.isMobile ) {
            this.$router.push( this.editPath );
        } else {
            this.$bvModal.show( this.editModalId );
        }
    }

    /* 話題編集モーダルウィンドウを閉じた際の処理 */
    closeTopicEditModal(): void {
        // 引数のリセット
        this.topicEditParam = {
            type: 'edit',
            categories: [],
            topicId: '',
            domainId: '',
            title: '',
            desc: '',
            me: this.viewer,
            thumb: undefined,
            category: undefined,
            pinned: false,
            allow_attachment_type: AttachmentFileTypesDefault,
            acl: Acl.createDummy(),
        }
        this.shownTopicEditModal = false;
        console.log("topic-edit-modal closed.");
    }

    /* 投稿作成モーダルウィンドウを閉じた際の処理 */
    closeMessageEditModal(): void {
        // 引数のリセット
        this.type = 'create';
        this.modalId = '';
        this.modalTitle = '';
        this.modalMessage = '';
        this.modalPhotos = [];
        this.modalAcl = Acl.createDummy();
        this.shownMessageEditModal = false;
        console.log("message-edit-modal closed.");
    }

    /* コメント作成モーダルウィンドウを閉じた際の処理 */
    closeCommentEditModal(): void {
        // 引数のリセット
        this.commentEditModalParam = {
            commentId: '',
            messageId: '',
            message: '',
            photos: [],
            allow_attachment_type: AttachmentFileTypesDefault,
        }
        this.shownCommentEditModal = false;
        console.log("comment-edit-modal closed.");
    }

    interruptionProcess(param: { id: string, path: string }): void {
        this.$bvModal.hide(param.id);
        if( param.path) {
            this.$router.replace(param.path);
        }
    }

    toEditMessage(value: string): void {
        const message = this.messages.find( (m) => m.id === value );
        if(!message) return;
        if(this.isMobile) {
            const domainId = message.domainId;
            const topicId = message.topicId;
            this.$router.push(`/edit-message/${domainId}/${topicId}/${value}` );
        } else {
            // モーダル処理
            this.type = 'edit';
            this.modalId = message.id;
            this.modalTitle = message.title;
            this.modalMessage =  message.message;
            this.modalPhotos = message.photos;
            this.modalAcl = message.acl;
            this.$bvModal.show('modal-message');
        }
    }

    toDeleteMessage(value: string): void {
        this.deleteId = value;
        this.$bvModal.show('delete-modal');
    }

    afterDeleteProcess(): void {
        if( this.$route.path === `/${this.replaceParam.domainId}` ) { return; }
        this.$router.replace( `/${this.replaceParam.domainId}` );
    }

    // Feature
    hasFeature( keyword: FeatureName ): boolean { return allowFeature( keyword, this.$store ); }

    // Lifecycle
    beforeDestroy(): void {
        window.removeEventListener('resize', this.judgeMobile);
        this.$root.$off('bv::modal::show')
        this.$root.$off('bv::modal::hide')
        this.$root.$off("open-comment-edit-modal")
        if( this.unsubscribeMutation ) this.unsubscribeMutation()
        if( this.unsubscribeAction ) this.unsubscribeAction()
    }
    mounted(): void {
        this.$root.$on('bv::modal::show', (bvEvent: BvModalEvent, modalId: string) => {
            switch( modalId ) {
                case 'modal-topic':
                    this.shownTopicEditModal = true;
                    console.log("topic-edit-modal opened.");
                    break;
                case 'modal-message':
                    this.shownMessageEditModal = true;
                    console.log("message-edit-modal opened.");
                    break;
                case 'modal-comment':
                    this.shownCommentEditModal = true;
                    console.log("comment-edit-modal opened.");
                    break;
                default:
                    break;
            }
        })
        this.$root.$on('bv::modal::hidden', (bvEvent: BvModalEvent, modalId: string) => {
            switch( modalId ) {
                case 'modal-topic':
                    this.closeTopicEditModal();
                    break;
                case 'modal-message':
                    this.closeMessageEditModal();
                    break;
                case 'modal-comment':
                    this.closeCommentEditModal();
                    break;
                default:
                    break;
            }
        })
        this.$root.$on("open-comment-edit-modal", (param: CommentEditModalParam) => {
            // コメント編集モーダルを開く
            this.commentEditModalParam = param;
            this.$bvModal.show("modal-comment");
        })

        if( !this.$store ) return;

        this.unsubscribeMutation = this.$store.subscribe( ( action: ActionPayload, state: unknown ) => {
            if( this.shownTopicEditModal || this.shownMessageEditModal || this.shownCommentEditModal ) return;

            // 開いている話題が削除された -> 話題一覧に移動
            if( action.type == "topics/delete" ) {
                const domainId = this.domainId;
                const topicId = this.topicId;
                if( action.payload.topicId == topicId ) {
                    this.replaceParam = { domainId: domainId, topicId: topicId };
                    this.$root.$emit('show-error-modal', { msg: "話題が削除されたため、話題一覧に移動します", afterProcess: () => this.afterDeleteProcess() })
                }
            }
        });
        this.unsubscribeAction = this.$store.subscribeAction( {
            before: async ( action: ActionPayload ) => {
                // invalidate以外は無視する
                if( action.type != "topics/invalidate" ) return;

                // 同topic以外は無視する
                const context = action.payload as { domainId: string, topicId: string, newAcl: Acl, oldAcl: Acl, };
                if( context.domainId !== this.domainId || context.topicId != this.topicId ) return; // 関係無い

                // 読み取り権が変わってたら投稿一覧をロードする
                const me = this.$store.getters[ "users/me" ];
                const oldReadAuth = AclManager.readTopic( me, { domainId: this.domainId, acl: context.oldAcl, } );
                const newReadAuth = AclManager.readTopic( me, { domainId: this.domainId, acl: context.newAcl, } );
                if( newReadAuth && newReadAuth != oldReadAuth ) {
                    this.$store.dispatch( "fetchData", action.payload );
                }
            }
        })
    }

    // ナビゲーション前にデータをロードする
    beforeRouteEnter( to: Route, from: Route, next: NavigationGuardNext ): void {
        if( !to.query.search ) {
            store.dispatch('fetchData', { domainId: to.params.domainId, topicId: to.params.topicId, prevDomainId: from.params.domainId } );
        }
        next();
    }
    beforeRouteUpdate( to: Route, from: Route, next: NavigationGuardNext ): void {
        // パラメータのリセット
        store.dispatch("setMessageId", "");
        
        this.listRange = { top: 0, bot: INITIAL_DISPLAY_NUMBER };
        this.infiniteId += 1; // 無限ロードリセット

        if( from.params.domainId != to.params.domainId
            || from.params.topicId != to.params.topicId
            || from.params.messageId != to.params.messageId
        ) {
            store.dispatch('fetchData', { domainId: to.params.domainId, topicId: to.params.topicId, prevDomainId: from.params.domainId } );
        }
        next();
    }
    beforeRouteLeave( to: Route, from: Route, next: NavigationGuardNext ): void {
        this.infiniteId += 1;
        this.routeLeaveEvent(next);
    }
}
